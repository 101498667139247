import axios from 'axios';

var axiosIAInstance = axios.create();
axiosIAInstance.defaults.baseURL = process.env.REACT_APP_BASE_IA_URL;
axiosIAInstance.interceptors.request.use(
  function (config) {
    if (localStorage.getItem('userInfo')) {
      config['headers']['Authorization'] = `Bearer ${
        JSON.parse(localStorage.getItem('userInfo'))['access']
      }`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosIAInstance.interceptors.response.use(
  function (response) {
    if (response && Object.keys(response).length > 0) return response;
    else return Promise.reject(new Error('Empty response'));
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default axiosIAInstance;
