import React, { useState, useRef, useMemo, useEffect } from 'react';
import { imageBasePath, contentTypes } from '../../../constants';
import { bytesToSize, getContentTypeImg, getContentTypeURL, formatMegaBytes } from '../../../utils';
import styles from '../../../assets/styles/onboarding.module.scss';
import { FileUploader } from '../../../shared_elements';
import LinearProgress from '@material-ui/core/LinearProgress';
import { StartRecordingButton } from '../../../shared_ui_components';
import { Subscription } from 'controllers/_exports';
import { useRecorderStore } from 'shared_ui_components/VideoRecorder/VideoRecorder.store';

export default function Upload(props) {
  const inputContentRef = useRef();
  const {
    formData,
    handleClose = () => null,
    isCustom,
    page = 'onboarding',
    setFormData = () => null,
    onRecordingStart = () => null,
    onRecordingDiscard = () => null,
    onRecordingEnd = () => null,
    bypassSubscription = () => null,
    onAssessmentRemove = () => null,
    finalRecordingID, // Helps to save a reecording with a unique id in the store
  } = props;
  const [imageUploaded, setImageUploaded] = useState(null);
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [modalShown, setModalShown] = useState(false);
  let userInfo = JSON.parse(localStorage.getItem('userInfo'));

  const inputContent = (props) => {
    return (
      <div>
        {!['assessment'].includes(page) && (
          <div ref={inputContentRef} className={styles.fileUpload}>
            <img src={imageBasePath + 'dragdrop.png'} alt="drag and drop" />
            <div className={styles.FileUploadText}>
              <h6 className="paragraph">
                Drag and drop or <span className="linkBtn">Upload</span>
              </h6>
              <p className="textCenter supportText">
                {getAcceptedFileType() ? (
                  <>
                    Recommended file types: {getAcceptedFileType()} <br />
                  </>
                ) : null}
                (Max file size {formatMegaBytes(userInfo.selectedOrg?.max_file_upload_size ?? 100)})
              </p>
            </div>
          </div>
        )}
        <StartRecordingButton
          onRecordingStart={() => {
            onRecordingStart();
            handleClose();
          }}
          onRecordingDiscard={onRecordingDiscard}
          bypassSubscription={bypassSubscription}
          onRecordingEnd={onRecordingEnd}
          finalRecordingID={finalRecordingID}
        />
      </div>
    );
  };

  const handleReupload = () => {
    setTimeout(() => {
      inputContentRef?.current?.click();
    }, 30);
  };

  const cleanVideoID = useRecorderStore((s) => s?.cleanVideoID);
  const handleAssessmentRemove = () => {
    cleanVideoID(finalRecordingID);
    onAssessmentRemove(finalRecordingID);
  };

  const Preview = ({ fileWithMeta, meta }) => {
    const { name, percent, status, type } = meta;
    const { cancel, remove } = fileWithMeta;

    const previewUrl = useMemo(() => {
      if (status !== 'done') return '';
      return URL.createObjectURL(fileWithMeta.file);
    }, [fileWithMeta.file, status]);

    const isVideo = getContentTypeURL(formData.content ? formData.content : type)?.id === 1;

    useEffect(() => {
      if (modalShown) return;
      if (status === 'error_file_size') {
        Subscription.allow({ key: Subscription.keys.upload_size });
        setModalShown(true);
      }
    }, [status]);

    return (
      <div className="filePreviewComponent">
        <div className="fileWrapper">
          {isVideo ? (
            <video controls>
              <source src={previewUrl} type="video/mp4" />
              <source src={previewUrl} type="video/ogg" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <a href={previewUrl} target="_blannk">
              {meta?.type?.includes('image') && previewUrl ? (
                <img className="imagePreview" src={previewUrl} alt="Uploaded image" />
              ) : formData.content ? (
                getContentTypeImg(getContentTypeURL(formData.content).id, formData?.content)
              ) : type ? (
                getContentTypeImg(getContentTypeURL(type).id, formData?.content)
              ) : formData.content_type ? (
                getContentTypeImg(formData.content_type, formData?.content)
              ) : (
                <img src={imageBasePath + 'default_featured_grey.png'} alt="text" />
              )}
            </a>
          )}
          {/* Bottom buttons */}
          <div className="contentWrapper">
            <h6 className="heading6">{name}</h6>
            {status !== 'error_upload' && status !== 'error_file_size' ? (
              <>
                <p className="supportText flexNotCentered">
                  <span>
                    {status !== 'done' || !acceptedFiles.length ? (
                      'Uploading...'
                    ) : (
                      <span className="uploaded green flexCentered">
                        <img src={imageBasePath + 'green_check_rounded.svg'} alt="" />
                        Uploaded
                      </span>
                    )}
                  </span>
                  <span>{bytesToSize(meta.size)}</span>
                </p>
                <LinearProgress
                  className="primary"
                  variant="determinate"
                  value={percent === 100 && !acceptedFiles.length ? 95 : percent}
                />
              </>
            ) : null}
            {status === 'error_upload' || status === 'error_file_size' ? (
              <>
                <p className="supportText flexNotCentered">
                  <span className="failed">
                    {status === 'error_file_size'
                      ? `Your file size is larger than ${formatMegaBytes(
                          userInfo.selectedOrg?.max_file_upload_size ?? 100
                        )}`
                      : 'Failed...'}
                  </span>
                  <span>{bytesToSize(meta.size)}</span>
                </p>
                <LinearProgress className="secondary" variant="determinate" value={percent} />
              </>
            ) : null}
          </div>
        </div>
        <ul className="listInline textCenter buttonList">
          <li className="listInlineItem">
            <button
              className="removeBtn flexCentered"
              onClick={() => {
                cancel();
                remove();
                handleAssessmentRemove();
                props.handleChange('content', '');
                if (isCustom || page == 'training') {
                  props.handleChange('content_type', 0);
                }
              }}
            >
              <img src={imageBasePath + 'minus_circle.svg'} />
              <span className="heading6">Remove</span>
            </button>
          </li>
          {!['assessment'].includes(page) && (
            <li className="listInlineItem">
              <button
                className="removeBtn flexCentered"
                onClick={() => {
                  handleReupload();
                  cancel();
                  remove();
                  props.handleChange('content', '');
                  if (isCustom || page == 'training') {
                    props.handleChange('content_type', 0);
                  }
                }}
              >
                <img src={imageBasePath + 'reupload.svg'} />
                <span className="heading6">Reupload</span>
              </button>
            </li>
          )}
        </ul>
      </div>
    );
  };

  const uploadImage = (files) => {
    if (files && files.length) {
      props.handleUploadChange(files);
    } else {
      return;
    }
  };

  const onImageUpload = (flag) => {
    setImageUploaded(flag);
    uploadImage();
  };

  const getAcceptedFileType = () => {
    if (formData.content_type && !isCustom && page == 'onboarding') {
      const accept = contentTypes.find((content) => content.id === formData.content_type);
      return accept && accept.extensions && accept.extensions.length
        ? accept.extensions.toString()
        : null;
    }
  };

  return (
    <div>
      {!acceptedFiles.length && formData.content ? (
        <div className="dzu-dropzone">
          <div className="filePreviewComponent">
            <div className="fileWrapper">
              {getContentTypeURL(formData.content)?.id === 1 ? (
                <>
                  <video controls>
                    <source src={formData.content} type="video/mp4" />
                    <source src={formData.content} type="video/ogg" />
                    Your browser does not support the video tag.
                  </video>
                </>
              ) : (
                getContentTypeImg(getContentTypeURL(formData.content)?.id, formData?.content)
              )}
            </div>
            <ul className="listInline textCenter buttonList">
              <li className="listInlineItem">
                <button
                  className="removeBtn flexCentered"
                  onClick={() => {
                    handleAssessmentRemove();
                    props.handleChange('content', '');
                    if (isCustom || page == 'training') {
                      props.handleChange('content_type', 0);
                    }
                  }}
                >
                  <img src={imageBasePath + 'minus_circle.svg'} />
                  <span className="heading6">Remove</span>
                </button>
              </li>
              {!['assessment'].includes(page) && (
                <li className="listInlineItem">
                  <button
                    className="removeBtn flexCentered"
                    onClick={() => {
                      props.handleChange('content', '');
                      if (isCustom || page == 'training') {
                        props.handleChange('content_type', 0);
                      }
                    }}
                  >
                    <img src={imageBasePath + 'reupload.svg'} />
                    <span className="heading6">Reupload</span>
                  </button>
                </li>
              )}
            </ul>
          </div>
        </div>
      ) : (
        <FileUploader
          inputContent={inputContent}
          maxFiles={1}
          accept="*" // {getAcceptedFileType()}
          uploadImage={uploadImage}
          onImageUpload={onImageUpload}
          maxSizeBytes={1024 * 1024 * userInfo.selectedOrg?.max_file_upload_size ?? 100}
          PreviewComponent={Preview}
          acceptedFiles={acceptedFiles}
          setAcceptedFiles={setAcceptedFiles}
          setFormData={setFormData}
          page={page}
          finalRecordingID={finalRecordingID}
        />
      )}
    </div>
  );
}
