import { Tooltip, withStyles } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import classes from './Autorating.module.scss';
import AIFeedback from 'shared_ui_components/AIFeatures/AIFeedback/AIFeedback';
import Organization from 'infrastructure/Organization';
import { FeatureFlag } from 'controllers/_exports';

const ShowFilledWithAI = ({ ai_feedback = '', ai_feedback_status }) => {
  const TooltipStyled = withStyles(() => ({
    tooltip: {
      backgroundColor: 'black',
      color: 'white',
    },
    arrow: {
      color: 'black',
    },
  }))(Tooltip);

  if (FeatureFlag.enabled('AUTORATING') && !Boolean(Organization.getSelectedOrg()?.ai_integration))
    return null;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
      <div className={classes.container}>
        <div>Feedback</div>
        <TooltipStyled title="Rating and feedback has been generated by AI." arrow placement="top">
          <HelpOutline style={{ fontSize: '1rem' }} />
        </TooltipStyled>
      </div>
      <AIFeedback ai_feedback={ai_feedback} ai_feedback_status={ai_feedback_status} />
    </div>
  );
};

export default ShowFilledWithAI;
