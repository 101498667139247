import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Popper from '@material-ui/core/Popper';
import { Grid, Avatar, Paper, Menu, MenuItem, Button } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import { imageBasePath } from '../constants';
import { toastFlashMessage, lastActivity } from '../utils';
import {
  globalGetService,
  globalPostService,
  globalDeleteService,
} from '../utils/globalApiServices';
import { CustomButton, InputSelect, NoResult } from '../shared_ui_components';
import { GlobalContext } from '../context/GlobalContext';
import Shimmer from './Shimmer';
import { acceptOwnershipTranfer } from '../features/settings/apiServices';
import { ConfirmationDialog } from '../shared_elements';
import { useLearningPathsStore } from 'features/training/components/LearningPaths/LearningPaths.store';
import { useChangeSalesRepViewMutation } from 'features/salesRepView/SalesRepView.queries';
import RepPovBanner from 'features/salesRepView/components/RepPovBanner/RepPovBanner';
import { useSalesRepViewStore } from 'features/salesRepView/SalesRepView.store';
import { useSalesRepView } from 'features/salesRepView/SalesRepView.hook';
import Organization from 'infrastructure/Organization';
// const useStyles = makeStyles((theme) => ({
//     root: {
//         width: auto,
//     },
// }));
const options = [
  { value: 'all', label: 'All' },
  { value: 0, label: 'Unread' },
  { value: 2, label: 'Dismissed' },
];

export default function PositionedPopper(props) {
  const { open = false, index, setOpen } = props;
  const [moreanchorEl, setmoreAnchorEl] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [notifications, notificationsSet] = useState([]);
  const [loader, loaderSet] = useState(false);
  const [viewMoreLoader, viewMoreLoaderSet] = useState(false);
  const [pagination, paginationSet] = useState({});
  const [notificationId, setNotificationId] = useState('');
  const [openOwnerDialog, setOpenOwnerDialog] = useState(false);
  const location = useLocation();
  const { state, dispatch } = useContext(GlobalContext);
  let history = useHistory();

  const handlemoreClick = (event, key) => {
    setmoreAnchorEl(event.currentTarget);
    setCurrentIndex(key);
  };

  useEffect(() => {
    if (open) {
      loaderSet(true);
      getNotifications();
    }
  }, [open]);

  const getNotifications = (data = {}) => {
    globalGetService('/notifications/', data).then((response) => {
      loaderSet(false);
      viewMoreLoaderSet(false);
      if (response.statusCode === 200) {
        if (data.status) {
          paginationSet(response.data.pagination);
          notificationsSet(response.data.list);
        } else if (data.page) {
          let filteredNotifications = response.data.list.filter(({ status }) =>
            [0, 1].includes(status)
          );
          paginationSet(response.data.pagination);
          notificationsSet([...notifications, ...filteredNotifications]);
        } else {
          let filteredNotifications = response.data.list.filter(({ status }) =>
            [0, 1].includes(status)
          );
          paginationSet(response.data.pagination);
          notificationsSet(filteredNotifications);
        }
      }
    });
  };

  const updateNotification = (notification, status) => {
    globalPostService('/update-notification/', {
      notification_id: notification.id,
      status: status,
    }).then((response) => {
      setCurrentIndex(null);
      setmoreAnchorEl(null);
      if (response.statusCode === 200) {
        if (status === 1) {
          notificationsSet(
            notifications.map((mnotification) =>
              mnotification.id === notification.id
                ? { ...mnotification, status: status }
                : mnotification
            )
          );
        } else if (status === 2) {
          notificationsSet(notifications.filter(({ id }) => id != notification.id));
        } else {
        }
        dispatch({
          type: 'NOTOFICATION_COUNT',
          payload: notifications.filter(
            (mnotification) => mnotification.id != notification.id && mnotification.status == 0
          )?.length,
        });
        // toastFlashMessage(response.message, 'success');
      } else {
        toastFlashMessage(response.message, 'error');
      }
    });
  };

  const deleteNotification = (notification) => {
    globalDeleteService(`/notification/${notification.id}/delete/`).then((response) => {
      setCurrentIndex(null);
      setmoreAnchorEl(null);
      if (response.statusCode === 200) {
        notificationsSet(notifications.filter(({ id }) => id != notification.id));
        dispatch({
          type: 'NOTOFICATION_COUNT',
          payload: notifications.filter(
            (mnotification) => mnotification.id != notification.id && mnotification.status == 0
          )?.length,
        });
        toastFlashMessage(response.message, 'success');
      } else {
        toastFlashMessage(response.message, 'success');
      }
    });
  };

  const dismissAllNotifications = () => {
    globalPostService('/update-notification/', {
      notification_id: 'all',
      status: 2,
    }).then((response) => {
      setCurrentIndex(null);
      setmoreAnchorEl(null);
      if (response.statusCode === 200) {
        notificationsSet([]);
        dispatch({
          type: 'NOTOFICATION_COUNT',
          payload: 0,
        });
        toastFlashMessage('Notifications dismissed', 'success');
      } else {
        toastFlashMessage(response.message, 'success');
      }
    });
  };

  const viewMore = () => {
    viewMoreLoaderSet(true);
    getNotifications({ page: pagination.current_page + 1 });
    paginationSet({
      ...pagination,
      current_page: pagination.current_page + 1,
    });
  };

  const filter = (status) => {
    loaderSet(true);
    if (status === 'all') {
      getNotifications();
    } else {
      getNotifications({ status: status });
    }
  };
  const setActiveTabRep = useLearningPathsStore((state) => state?.setActiveTabRep);
  const { mutate, isLoading: mutationLoading } = useChangeSalesRepViewMutation();
  const { isConfirmationOpen, closeConfirmation, openConfirmation } = useSalesRepViewStore();
  const { switchOff, isActive } = useSalesRepView();
  const switchOn = () => {
    if (mutationLoading) return;
    mutate({ status: true });
    history.push('/training');
    setActiveTabRep(true);
  };

  const handleSubmit = () => {
    closeConfirmation();
    isActive ? switchOff() : switchOn();
  };

  const redirectNotification = (notification) => {
    if (
      notification.redirect === 'onboarding' ||
      notification.redirect === 'onboaring_not_completed_yet' ||
      notification.redirect === 'onboaring_not_published_yet'
    ) {
      history.push('/onboarding');
      updateNotification(notification, 1);
    } else if (
      notification.redirect === 'training' ||
      notification.redirect === 'new_training_content'
    ) {
      if (notification.redirect === 'training' && notification.type === 'assessment_submitted') {
        history.push(`/training?assessment=${notification.redirect_id}`);
      } else if (
        notification.redirect === 'training' &&
        notification.type === 'assessment_feedback'
      ) {
        history.push(`/training?assessment=${notification.redirect_id}`);
      } else {
        history.push('/training');
      }
      updateNotification(notification, 1);
    } else if (notification.redirect === 'learning_path') {
      if ((Organization.isAdmin() || Organization.isLead()) && !isActive) {
        openConfirmation();
      } else {
        history.push('/training');
        setActiveTabRep(true);
      }
      updateNotification(notification, 1);
    } else if (notification.redirect === 'invite' || notification.redirect === 'invite_accept') {
      history.push(`${window.location.pathname}?invite=true&email=${notification.from_user.email}`);
      updateNotification(notification, 1);
    } else if (
      notification.redirect === 'checkin' ||
      notification.redirect === 'checkin_reminder'
    ) {
      history.push('/tracking?active=checkin&openCheckin=true');
      updateNotification(notification, 1);
    } else if (notification.redirect === 'user') {
      history.push('/users');
      updateNotification(notification, 1);
    } else if (notification.type === 'pending_ownership') {
      updateNotification(notification, 1);
      setNotificationId(notification.id);
      setOpenOwnerDialog(true);
    } else if (notification.type === 'ownership') {
      setTimeout(() => {
        history.push({ search: '?page=1' });
      }, 2000);
      toastFlashMessage(notification.message, 'success');
    } else if (notification.redirect === 'subscription') {
      history.push('/subscription');
      updateNotification(notification, 1);
    } else if (notification.redirect === 'pulse') {
      history.push('/pulse');
      updateNotification(notification, 1);
    } else if (
      notification.redirect === 'roleplay' &&
      notification.type === 'navigate_to_roleplay_page'
    ) {
      history.push(`/roleplay`);
      updateNotification(notification, 1);
    } else if (notification.type === 'read_only') {
      updateNotification(notification, 1);
    } else {
      // history.push('/');
    }
    if (setOpen && typeof setOpen === 'function') setOpen(false);
  };

  const handleOwnerShipTransfer = () => {
    acceptOwnershipTranfer({
      notification: notificationId,
    }).then((response) => {
      if (response.statusCode === 200) {
        const selectedOrg = response.data?.organisations?.find((item) => item.selected);
        localStorage.setItem('userInfo', JSON.stringify({ ...response.data, selectedOrg }));
        if (location.pathname === '/users') {
          window.location.reload();
        } else {
          history.push('/users');
        }
        toastFlashMessage(response.message, 'success');
      } else {
        toastFlashMessage(response.message, 'error');
      }

      setOpenOwnerDialog(false);
    });
  };

  return (
    <div>
      <ConfirmationDialog
        open={isConfirmationOpen}
        title={
          <span>
            Are you sure you want to switch to{' '}
            <b>{isActive ? 'your original role' : 'Learner Mode'}</b>?
          </span>
        }
        handleClose={closeConfirmation}
        rejectText="No, cancel"
        acceptText="Yes, change view"
        image={imageBasePath + 'admin_view_switch.svg'}
        handleSubmit={handleSubmit}
        description={isActive ? '' : 'Please note that some features may be limited in this view.'}
      />
      <ConfirmationDialog
        open={openOwnerDialog}
        title={
          <>
            Are you sure you want to accept <br /> ownership of this organization?
          </>
        }
        description=""
        acceptText="Yes, Accept"
        rejectText="No, Deny"
        handleClose={() => {
          setOpenOwnerDialog(false);
        }}
        handleSubmit={() => {
          handleOwnerShipTransfer();
        }}
        loader={false}
      />
      <Popper open={open} transition disablePortal={true} className="notificationPlacement">
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <div
                className="notificationPopupWrapper"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                <div className="notificationHeader">
                  <Grid container alignItems="center" className="" spacing={2}>
                    <Grid item xs={7}>
                      <h5 className="heading5">Notifications</h5>
                      {!loader && notifications.filter(({ status }) => status === 0).length > 0 ? (
                        <p className="supportText">
                          You have{' '}
                          <span className="notificationCountText">
                            {notifications.filter(({ status }) => status === 0).length} new{' '}
                          </span>
                          notifications{' '}
                        </p>
                      ) : null}
                      {notifications?.length > 0 && (
                        <Button className="primaryBtn dismissBtn" onClick={dismissAllNotifications}>
                          Dismiss all
                        </Button>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={5}
                      className="textRight"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      <div className="selectBlk">
                        <InputSelect
                          defaultValue={{ value: 'all', label: 'All' }}
                          closeMenuOnSelect={true}
                          options={options}
                          isSearchable={true}
                          placeholder={'Select'}
                          noOptionsMessage={() => 'No matches found'}
                          onChange={(selectedOption) => filter(selectedOption.value)}
                          optionBackground="#fff"
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div className="notificationBody">
                  <Grid container className="notificationItems" spacing={2}>
                    <Grid item xs={12} className="notificationItemOuterWrapper" spacing={0}>
                      {loader ? (
                        <Shimmer type="notificationShimmer" />
                      ) : notifications.length ? (
                        <>
                          {notifications.map((notification, key) => (
                            <div
                              key={key}
                              className="notificationItemWrapper"
                              onClick={() => redirectNotification(notification)}
                            >
                              {/* {
                                    notification.type === 'user' ? 
                                    
                                    : null
                                  } */}
                              <div className="notificationImgWrapper">
                                {!notification.from_user ? (
                                  <img
                                    className="notificationImg"
                                    src={imageBasePath + 'logo_single.png'}
                                    alt="Profile"
                                  />
                                ) : notification.from_user?.profile_pic ? (
                                  <img
                                    className="notificationImg"
                                    src={notification.from_user.profile_pic}
                                    alt="Profile"
                                  />
                                ) : (
                                  <Avatar className="notificationAvatar">
                                    {notification.from_user?.email?.substring(0, 1)}
                                  </Avatar>
                                )}
                              </div>
                              <div className="notificationText">
                                <p className="notificationCustomText paragraph">
                                  {notification.message}
                                  <p className="notificationTime supportText">
                                    {lastActivity(notification.created_at)}
                                  </p>
                                </p>
                              </div>
                              {notification.status === 0 ? (
                                <span className="notificationStatus"></span>
                              ) : null}
                              <span className="moreBtn">
                                <a
                                  aria-controls={'simple-menu-' + key}
                                  className={'more'}
                                  aria-haspopup="true"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    handlemoreClick(e, key);
                                  }}
                                >
                                  <img
                                    className="more"
                                    src={imageBasePath + 'more_vertical_gray.svg'}
                                    alt="More"
                                    style={{ verticalAlign: 'middle' }}
                                  />
                                </a>
                              </span>
                              <Menu
                                id={'simple-menu-' + index}
                                anchorEl={moreanchorEl}
                                keepMounted
                                open={currentIndex == key && Boolean(moreanchorEl) ? true : false}
                                onClose={(e) => {
                                  setmoreAnchorEl(null);
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setCurrentIndex(null);
                                }}
                                className="moreMenu"
                              >
                                {notification.status === 0 ? (
                                  <MenuItem
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      updateNotification(notification, 1);
                                    }}
                                  >
                                    <img
                                      src={imageBasePath + 'marks_as_red.svg'}
                                      alt="Mark it as read"
                                    />
                                    <span className="supportText">Mark it as read</span>
                                  </MenuItem>
                                ) : null}
                                {notification.status !== 2 ? (
                                  <MenuItem
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      updateNotification(notification, 2);
                                    }}
                                  >
                                    <img
                                      src={imageBasePath + 'dismiss.svg'}
                                      alt="Dismiss"
                                      style={{ width: '10px' }}
                                    />
                                    <span className="supportText">Dismiss</span>
                                  </MenuItem>
                                ) : null}
                                <MenuItem
                                  className="deleteAction"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    deleteNotification(notification);
                                  }}
                                >
                                  <img src={imageBasePath + 'delete_red.svg'} alt="delete" />
                                  <span className="supportText">Delete</span>
                                </MenuItem>
                              </Menu>
                            </div>
                          ))}
                        </>
                      ) : (
                        <NoResult
                          className="notificationNostate"
                          image="no_notification.svg"
                          title="No Notifications"
                          subtext="Currently, you've no notifications"
                        />
                      )}
                      {viewMoreLoader ? (
                        <p className="paragraph textCenter">Please wait...</p>
                      ) : null}
                    </Grid>
                  </Grid>
                </div>
                {pagination.current_page !== pagination.number_of_pages ? (
                  <div className="notificationFooter">
                    <CustomButton
                      className="linkBtn"
                      children="View More"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        viewMore();
                      }}
                    />
                  </div>
                ) : null}
              </div>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
}
