import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';

import styles from './ReviewQualifiedRoleplay.module.css';
import { useGetRoleplayView } from 'features/roleplay/Roleplay.queries';
import QualifiedNavigationButtons from './QualifiedNavigationButtons';
import ShowRatingCriteria from './ShowRatingCriteria';
import ShowFeedback from './ShowFeedback';
import ScenarioHeader from './ScenarioHeader';
import RoleplayHeader from './RoleplayHeader';
import RoleplayVideo from './RoleplayVideo';
import { useRoleplayStore } from 'features/roleplay/Roleplay.store';
import ShowFilledWithAI from '../Autorating/ShowFilledWithAI';
const ReviewQualifiedRoleplay = () => {
  const { uuid } = useParams();
  const { data } = useGetRoleplayView({ roleplay_id: uuid, enabled: !!uuid });
  const secondAttempt = useRoleplayStore((state) => state?.secondAttempt);
  const hideSecondAttempt = useRoleplayStore((state) => state?.hideSecondAttempt);

  const scenarios = useMemo(() => data?.scenarios || [], [data]);
  const [selectedScenario, setSelectedScenario] = useState({});

  useEffect(() => {
    if (scenarios?.length <= 0) return;
    setSelectedScenario(scenarios[0]);
    hideSecondAttempt();
  }, [hideSecondAttempt, scenarios]);

  const changeScenario = (increment = 1) => {
    try {
      hideSecondAttempt();
      const index = scenarios.findIndex((r) => r?.uuid === selectedScenario?.uuid);
      const newScenario = scenarios[index + increment];
      if (newScenario) setSelectedScenario(newScenario);
    } catch (error) {}
  };

  return (
    <div className={styles.mainContainer}>
      <Grid container direction="row" spacing={3}>
        <Grid item xs={12} lg={8}>
          <div className={styles.card}>
            <RoleplayHeader roleplay={data} selectedScenario={selectedScenario} />
            <RoleplayVideo selectedScenario={selectedScenario} />
          </div>
        </Grid>
        <Grid item xs={12} lg={4}>
          <div className={styles.card}>
            <ScenarioHeader roleplay={data} />
            <ShowRatingCriteria selectedScenario={selectedScenario} />
            {selectedScenario?.response?.ai_raiting === false ? (
              <ShowFeedback
                comment={
                  !secondAttempt
                    ? selectedScenario?.response?.comment
                    : selectedScenario?.response_retake?.comment || ''
                }
              />
            ) : (
              <ShowFilledWithAI
                ai_feedback={
                  !secondAttempt
                    ? selectedScenario?.response?.comment
                    : selectedScenario?.response_retake?.comment || ''
                }
                ai_feedback_status={'SUCCEEDED'}
              />
            )}
            <QualifiedNavigationButtons
              changeScenario={changeScenario}
              scenarios={scenarios}
              selectedScenario={selectedScenario}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ReviewQualifiedRoleplay;
