import React, { useMemo } from 'react';
import { useRoleplayStore } from 'features/roleplay/Roleplay.store';
import { useAutoFillStore } from '../Autorating/AutoFill.store';
import { TextArea } from 'shared_ui_components';
import ShowFeedback from '../ReviewQualifiedRoleplay/ShowFeedback';
import FeedbackAI from '../Autorating/FeedbackAI';
const DescriptionComment = () => {
  const reviewScenarioSelected = useRoleplayStore((state) => state?.reviewScenarioSelected);
  const formDataScenarioReview = useRoleplayStore((state) => state?.formDataScenarioReview);
  const setFormDataScenarioReview = useRoleplayStore((state) => state?.setFormDataScenarioReview);
  const responseRetake = useRoleplayStore((state) => state?.responseRetake);

  const filledWithAI = useAutoFillStore((state) => state?.filledWithAI);
  const statusAI = useAutoFillStore((state) => state?.statusAI);

  const handleChange = (value) => {
    setFormDataScenarioReview({ ...formDataScenarioReview, comment: value });
  };
  const showRetake = useMemo(() => Object.keys(responseRetake).length > 0, [responseRetake]);
  const isRetakeQualified = useMemo(() => {
    if (Object.keys(reviewScenarioSelected).length > 0) {
      if (reviewScenarioSelected?.response_retake?.rating_criterials.length > 0) return true;
      else return false;
    } else return false;
  }, [reviewScenarioSelected]);

  return (
    <div style={{ marginBottom: '1rem' }}>
      {!isRetakeQualified && (reviewScenarioSelected?.response?.comment === '' || showRetake) ? (
        <>
          {filledWithAI ? (
            <FeedbackAI
              ai_feedback={formDataScenarioReview.comment}
              ai_feedback_status={statusAI}
            />
          ) : (
            <>
              <p className="supportText" style={{ marginBottom: '0.25rem' }}>
                Comment
              </p>
              <TextArea
                rows={3}
                value={formDataScenarioReview.comment}
                onChange={(e) => handleChange(e.target.value)}
              />
            </>
          )}
        </>
      ) : (
        <ShowFeedback
          comment={
            !showRetake ? reviewScenarioSelected?.response?.comment : responseRetake?.comment
          }
        />
      )}
    </div>
  );
};

export default DescriptionComment;
