import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { toastFlashMessage } from 'utils';
import {
  getRoleplayProgressService,
  getRoleplaysToCompleteService,
  getRoleplaysToReviewService,
  getListUsersService,
  getListRoleplaysService,
  getListScenariosPerRoleplayService,
  createAssignRoleplay,
  createSelfAssignRoleplay,
  uploadRecordingService,
  updateRecordingInScenarioService,
  getRoleplayview,
  getRoleplayHistoryService,
  submitRoleplay,
  getRoleplayTeamService,
  reviewScenario,
  submitReview,
  roleplaysToPassService,
  createCustomRoleplay,
  unassignRoleplayService,
  deleteRoleplayService,
  reassignRoleplayService,
  getListUsersRepService,
  getListUserRepService,
  createReminder,
  roleplayProcessAI,
  getRatingWithAI,
} from './Roleplay.services';
import Organization from 'infrastructure/Organization';
import { useAutoFillStore } from './Components/Autorating/AutoFill.store';

export const ROLEPLAY_CACHE_KEYS = {
  SCENARIOS: 'roleplay_scenarios',
  ASSIGNED_TO_ME: 'roleplay_to_complete',
  PROGRESS_GRAPH: 'roleplays_progress',
  TO_REVIEW: 'roleplay_to_review',
  LIST_USERS: 'roleplay_list_users',
  LIST_ROLEPLAYS: 'roleplay_list_roleplays',
  LIST_SCENARIOS_PER_ROLEPLAY: 'roleplay_list_scenarios_per_roleplay',
  ROLEPLAY_VIEW: 'roleplay_roleplay_view',
  HISTORY: 'roleplay_history',
  MY_TEAM: 'roleplay_my_team',
  TO_PASS: 'roleplay_to_pass_home',
  LIST_USERS_REPS: 'roleplay_list_users_reps',
  LIST_USER_ROLEPLAY: 'roleplay_list_user_roleplay',
};

// Roleplays Dashboard

export const useGetRoleplaysToCompleteQuery = ({ enabled }) => {
  return useQuery({
    queryKey: [ROLEPLAY_CACHE_KEYS.ASSIGNED_TO_ME],
    queryFn: getRoleplaysToCompleteService,
    enabled,
  });
};

export const useGetRoleplaysToReviewQuery = ({ enabled }) => {
  return useQuery({
    queryKey: [ROLEPLAY_CACHE_KEYS.TO_REVIEW],
    queryFn: getRoleplaysToReviewService,
    enabled,
  });
};

export const useGetRoleplayHistoryQuery = ({ enabled, time = 'all-time' }) => {
  return useQuery({
    queryKey: [ROLEPLAY_CACHE_KEYS.HISTORY, time],
    queryFn: () => getRoleplayHistoryService({ time }),
    enabled,
  });
};

export const useGetRoleplayTeamQuery = ({ time = 'all-time' }) => {
  return useQuery({
    queryKey: [ROLEPLAY_CACHE_KEYS.MY_TEAM, time],
    queryFn: () => getRoleplayTeamService({ time }),
    enabled: !Organization.isRep(),
  });
};

export const useGetRoleplayProgressQuery = ({ home, time = 'all-time' }) => {
  return useQuery({
    queryKey: [ROLEPLAY_CACHE_KEYS.PROGRESS_GRAPH, home, time],
    queryFn: () => getRoleplayProgressService({ home, time }),
  });
};

export const useGetListUsersQuery = () => {
  return useQuery({
    queryKey: [ROLEPLAY_CACHE_KEYS.LIST_USERS],
    queryFn: getListUsersService,
  });
};

export const useGetListRoleplaysQuery = () => {
  return useQuery({
    queryKey: [ROLEPLAY_CACHE_KEYS.LIST_ROLEPLAYS],
    queryFn: getListRoleplaysService,
  });
};

export const useGetListScenariosPerRoleplaysQuery = ({ roleplayUuid = '' }) => {
  return useQuery({
    queryKey: [ROLEPLAY_CACHE_KEYS.LIST_SCENARIOS_PER_ROLEPLAY],
    queryFn: getListScenariosPerRoleplayService({ roleplayUuid }),
  });
};

export const useCreateAssignRoleplay = () => {
  const queryClient = useQueryClient();
  return useMutation(({ roleplay }) => createAssignRoleplay({ roleplay }), {
    onSuccess: (response) => {
      toastFlashMessage(response?.message, 'success');
      queryClient.invalidateQueries([ROLEPLAY_CACHE_KEYS.ASSIGNED_TO_ME]);
      queryClient.invalidateQueries([ROLEPLAY_CACHE_KEYS.PROGRESS_GRAPH]);
      queryClient.invalidateQueries([ROLEPLAY_CACHE_KEYS.TO_REVIEW]);
      queryClient.invalidateQueries([ROLEPLAY_CACHE_KEYS.MY_TEAM]);
      queryClient.invalidateQueries([ROLEPLAY_CACHE_KEYS.LIST_USERS_REPS]);
    },
  });
};

export const useCreateSelfAssignRoleplay = () => {
  const queryClient = useQueryClient();
  return useMutation(({ roleplay }) => createSelfAssignRoleplay({ roleplay }), {
    onSuccess: (response) => {
      toastFlashMessage(response?.message, 'success');
      queryClient.invalidateQueries([ROLEPLAY_CACHE_KEYS.ASSIGNED_TO_ME]);
      queryClient.invalidateQueries([ROLEPLAY_CACHE_KEYS.PROGRESS_GRAPH]);
      queryClient.invalidateQueries([ROLEPLAY_CACHE_KEYS.TO_REVIEW]);
    },
  });
};

export const useGetRoleplayView = ({ roleplay_id, enabled }) =>
  useQuery(
    [ROLEPLAY_CACHE_KEYS.ROLEPLAY_VIEW, roleplay_id],
    () => getRoleplayview({ roleplay_id }),
    {
      enabled,
    }
  );

// Recording

export const useUploadRecordingMutation = () => {
  return useMutation(uploadRecordingService);
};

export const useUpdateRecordingInScenarioMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(updateRecordingInScenarioService, {
    onSuccess: (response, data) => {
      toastFlashMessage(response?.message, 'success');
      queryClient.invalidateQueries([ROLEPLAY_CACHE_KEYS.ROLEPLAY_VIEW, data.roleplay_id]);
    },
  });
};

//submit roleplay

export const useSubmitRoleplay = () => {
  const queryClient = useQueryClient();
  return useMutation(({ roleplay_id }) => submitRoleplay({ roleplay_id }), {
    onSuccess: (response) => {
      toastFlashMessage(response?.message, 'success');
      queryClient.invalidateQueries([ROLEPLAY_CACHE_KEYS.MY_TEAM]);
    },
  });
};

//AI processing

export const useSubmitRoleplayForProcessingWithAI = () => {
  return useMutation(({ org_id, roleplay_id, requestBody }) =>
    roleplayProcessAI({ org_id, roleplay_id, requestBody })
  );
};

export const useReviewScenario = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ roleplay, scenario, body }) => reviewScenario({ roleplay, scenario, body }),
    {
      onSuccess: (response) => {
        toastFlashMessage(response?.message, 'success');
        queryClient.invalidateQueries([ROLEPLAY_CACHE_KEYS.ROLEPLAY_VIEW]);
      },
    }
  );
};

export const useSubmitReview = () => {
  const queryClient = useQueryClient();
  return useMutation(({ roleplay_id }) => submitReview({ roleplay_id }), {
    onSuccess: (response) => {
      toastFlashMessage(response?.message, 'success');
      queryClient.invalidateQueries([ROLEPLAY_CACHE_KEYS.MY_TEAM]);
    },
  });
};

const invalidateQueries = (response, queryClient) => {
  toastFlashMessage(response?.message, 'success');
  queryClient.invalidateQueries([ROLEPLAY_CACHE_KEYS.TO_REVIEW]);
  queryClient.invalidateQueries([ROLEPLAY_CACHE_KEYS.ASSIGNED_TO_ME]);
  queryClient.invalidateQueries([ROLEPLAY_CACHE_KEYS.PROGRESS_GRAPH]);
  queryClient.invalidateQueries([ROLEPLAY_CACHE_KEYS.MY_TEAM]);
};

export const useUnassignRoleplayMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(({ roleplay_id }) => unassignRoleplayService({ roleplay_id }), {
    onSuccess: (response) => invalidateQueries(response, queryClient),
  });
};
export const useDeleteRoleplayMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(({ roleplay_id }) => deleteRoleplayService({ roleplay_id }), {
    onSuccess: (response) => invalidateQueries(response, queryClient),
  });
};
export const useReassignRoleplayMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(({ roleplay_id }) => reassignRoleplayService({ roleplay_id }), {
    onSuccess: (response) => invalidateQueries(response, queryClient),
  });
};

// Home
export const useGetRoleplayToPassQuery = () => {
  return useQuery({
    queryKey: [ROLEPLAY_CACHE_KEYS.TO_PASS],
    queryFn: roleplaysToPassService,
  });
};

export const useCreateCustomRoleplay = () => {
  const queryClient = useQueryClient();
  return useMutation(({ roleplay }) => createCustomRoleplay({ roleplay }), {
    onSuccess: (response) => {
      toastFlashMessage(response?.message, 'success');
      queryClient.invalidateQueries([ROLEPLAY_CACHE_KEYS.ASSIGNED_TO_ME]);
      queryClient.invalidateQueries([ROLEPLAY_CACHE_KEYS.PROGRESS_GRAPH]);
      queryClient.invalidateQueries([ROLEPLAY_CACHE_KEYS.TO_REVIEW]);
      queryClient.invalidateQueries([ROLEPLAY_CACHE_KEYS.MY_TEAM]);
    },
  });
};

export const useGetListUsersRepQuery = () => {
  return useQuery({
    queryKey: [ROLEPLAY_CACHE_KEYS.LIST_USERS_REPS],
    queryFn: getListUsersRepService,
  });
};
export const useGetListUserRepQuery = ({ uuid = '' }) => {
  return useQuery({
    queryKey: [ROLEPLAY_CACHE_KEYS.LIST_USER_ROLEPLAY],
    queryFn: () => getListUserRepService({ uuid }),
  });
};

export const useCreateReminder = () => {
  const queryClient = useQueryClient();
  return useMutation(({ roleplay_user_uuid }) => createReminder({ roleplay_user_uuid }), {
    onSuccess: (response) => {
      toastFlashMessage(response?.message, 'success');
      queryClient.invalidateQueries([ROLEPLAY_CACHE_KEYS.TO_REVIEW]);
    },
  });
};

export const useGetReviewWithAIQuery = ({ org_id, roleplay_id, enabled }) => {
  const setResponseData = useAutoFillStore((state) => state?.setResponseData);
  const toggleIsError = useAutoFillStore((state) => state?.toggleIsError);
  const reset = useAutoFillStore((state) => state?.reset);
  return useQuery({
    queryKey: ['AI'],
    queryFn: () => getRatingWithAI({ org_id, roleplay_id }),
    enabled,
    onSuccess: (response) => {
      const scenarios = response.scenarios;
      const updatedResponse = scenarios.map((item) => ({
        ...item,
        status: item?.status ?? 'IN_PROGRESS',
        rating_criteria: item.rating_criteria
          ? item.rating_criteria.map((r) => ({
              ...r,
              rating: parseInt(r.rating, 10),
            }))
          : null,
      }));
      setResponseData(updatedResponse);
    },
    onError: () => {
      toggleIsError();
      reset();
    },
  });
};
