import { useEffect } from 'react';
import Organization from 'infrastructure/Organization';
import { FeatureFlag } from 'controllers/_exports';
import { SnackbarContent, withStyles, Snackbar } from '@material-ui/core';
import { ChevronRight, Forum, CheckCircle } from '@material-ui/icons';
import sparkle from 'assets/icons/sparkle.svg';
import classes from './Autorating.module.scss';
import { useAutoFillStore } from './AutoFill.store';
import { useRoleplayStore } from 'features/roleplay/Roleplay.store';
import { useGetReviewWithAIQuery } from 'features/roleplay/Roleplay.queries';

const AutoFillSnackbar = () => {
  const filledWithAI = useAutoFillStore((state) => state?.filledWithAI);
  const toggleFilledWithAI = useAutoFillStore((state) => state?.toggleFilledWithAI);
  const startEditAI = useAutoFillStore((state) => state?.startEditAI);
  const toggleStartEditAI = useAutoFillStore((state) => state?.toggleStartEditAI);
  const saveEdition = useAutoFillStore((state) => state?.saveEdition);
  const statusAI = useAutoFillStore((state) => state?.statusAI);
  const setStatus = useAutoFillStore((state) => state?.setStatus);
  const responseData = useAutoFillStore((state) => state?.responseData);
  const setTempReview = useAutoFillStore((state) => state?.setTempReview);
  const reset = useAutoFillStore((state) => state?.reset);
  const isError = useAutoFillStore((state) => state?.isError);

  const formDataScenarioReview = useRoleplayStore((state) => state?.formDataScenarioReview);
  const setFormDataScenarioReview = useRoleplayStore((state) => state?.setFormDataScenarioReview);
  const reviewScenarioSelected = useRoleplayStore((state) => state?.reviewScenarioSelected);
  const roleplayView = useRoleplayStore((state) => state?.roleplayView);
  const org_id = Organization.getSelectedOrg()?.slug;

  const { data, isLoading } = useGetReviewWithAIQuery({
    org_id,
    roleplay_id: roleplayView.uuid,
    enabled: filledWithAI,
  });

  useEffect(() => {
    if (!data || !filledWithAI || responseData?.length === 0) return;

    //get scenario id
    const index = responseData?.findIndex((s) => s?.scenario_id === reviewScenarioSelected?.uuid);

    if (index !== -1 && index !== undefined) {
      //filled according to the scenario
      setStatus(responseData[index]?.status);
      if (responseData[index]?.rating_criteria !== null) {
        responseData[index]?.rating_criteria.forEach((c) => {
          const index = formDataScenarioReview.rating_criterials_uuid.findIndex(
            (criteria) => criteria.rating_criteria_uuid === c.criteria_id
          );
          if (index !== -1) {
            formDataScenarioReview.rating_criterials_uuid[index].stars = c.rating;
            setFormDataScenarioReview({ ...formDataScenarioReview });
          }
        });
      }
      if (responseData[index]?.feedback !== null)
        setFormDataScenarioReview({
          ...formDataScenarioReview,
          comment: responseData[index]?.feedback,
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseData, filledWithAI, reviewScenarioSelected, data]);

  const autoFill = () => toggleFilledWithAI();
  const editAIRating = () => {
    toggleStartEditAI();
    //make a copy of formDataScenarioReview
    setTempReview({ ...formDataScenarioReview });
  };

  const handleResetAI = () => {
    reset();
    formDataScenarioReview?.rating_criterials_uuid?.forEach((criteria) => (criteria.stars = 0));
    setFormDataScenarioReview({ ...formDataScenarioReview, comment: '' });
  };

  const action = (
    <div
      style={{ display: 'flex', gap: '0.2rem', alignItems: 'center', cursor: 'pointer' }}
      className={classes.action}
      onClick={autoFill}
    >
      <span>Auto Fill</span>
      <ChevronRight style={{ fontSize: '0.9rem', color: '#0494FD' }} />
    </div>
  );

  const FillSnackbarStyled = withStyles((theme) => ({
    root: {
      backgroundColor: '#ECF7FF',
      border: '1px solid  #0494FD',
      color: '#0494FD',
      boxShadow: 'none',
      padding: '1px 12px',
      marginBottom: '1rem',
      flexWrap: 'nowrap',
      [theme.breakpoints.up('md')]: {
        minWidth: '238px',
      },
    },
  }))(SnackbarContent);

  const EditSnackbarStyled = withStyles((theme) => ({
    root: {
      backgroundColor: 'white',
      border: '1px solid  #90A4AE',
      color: '#272D37',
      boxShadow: 'none',
      padding: '1px 12px',
      marginBottom: '1rem',
      flexWrap: 'nowrap',
      [theme.breakpoints.up('md')]: {
        minWidth: '238px',
      },
    },
  }))(SnackbarContent);

  const editAction = (
    <div
      style={{
        display: 'flex',
        gap: '0.2rem',
        alignItems: 'center',
        cursor: 'pointer',
        color: '#0494FD',
      }}
      className={classes.action}
      onClick={editAIRating}
    >
      <span>Edit</span>
      <ChevronRight style={{ fontSize: '0.9rem', color: '#0494FD' }} />
    </div>
  );

  if (FeatureFlag.enabled('AUTORATING') && !Boolean(Organization.getSelectedOrg()?.ai_integration))
    return null;

  return (
    <>
      {!filledWithAI && !saveEdition && (
        <FillSnackbarStyled
          message={
            <div className={classes.message}>
              <Forum className={classes.iconChat} style={{ fontSize: '0.9rem' }} />
              <div>AI-Powered Feedback is available</div>
            </div>
          }
          action={action}
        />
      )}
      {filledWithAI && !startEditAI && !saveEdition && statusAI === 'SUCCEEDED' && (
        <EditSnackbarStyled
          message={
            <div className={classes.message}>
              <img src={sparkle} alt="sparkle" style={{ width: '1rem', height: '1rem' }} />
              <div style={{ fontSize: '12px', color: '#272D37' }}>
                The fields were filled in automatically using AI{' '}
              </div>
            </div>
          }
          action={editAction}
        />
      )}
      {saveEdition && (
        <Snackbar
          open={saveEdition}
          style={{ zIndex: '999999', marginTop: '2rem' }}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          className={classes.undoSnackbar}
          message={
            <div className={classes.message}>
              <CheckCircle className={classes.iconCheck} />
              <div>Feedback updated</div>
            </div>
          }
          action={
            <span className={classes.action} onClick={handleResetAI} style={{ cursor: 'pointer' }}>
              Undo
            </span>
          }
        />
      )}
      {filledWithAI && statusAI !== 'SUCCEEDED' && !isLoading && !isError && (
        <Snackbar
          open
          style={{ zIndex: '999999', marginTop: '2rem' }}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          className={classes.undoSnackbar}
          message={
            <div className={classes.message}>
              <CheckCircle className={classes.iconCheck} />
              <div>Feedback updated</div>
            </div>
          }
          action={
            <span className={classes.action} onClick={handleResetAI} style={{ cursor: 'pointer' }}>
              Undo
            </span>
          }
        />
      )}
    </>
  );
};

export default AutoFillSnackbar;
