import React, { useMemo } from 'react';
import { useRoleplayStore } from 'features/roleplay/Roleplay.store';
import { useAutoFillStore } from '../Autorating/AutoFill.store';
import { Rating } from '@material-ui/lab';
import RatingCriteriaTooltip from '../Common/RatingCriteriaTooltip';

const DescriptionRatingCriteria = () => {
  const roleplayView = useRoleplayStore((state) => state?.roleplayView);
  const reviewScenarioSelected = useRoleplayStore((state) => state?.reviewScenarioSelected);
  const formDataScenarioReview = useRoleplayStore((state) => state?.formDataScenarioReview);
  const setFormDataScenarioReview = useRoleplayStore((state) => state?.setFormDataScenarioReview);
  const responseRetake = useRoleplayStore((state) => state?.responseRetake);

  const filledWithAI = useAutoFillStore((state) => state?.filledWithAI);
  const startEditAI = useAutoFillStore((state) => state?.startEditAI);
  const statusAI = useAutoFillStore((state) => state?.statusAI);
  const tempReview = useAutoFillStore((state) => state?.tempReview);
  const setTempReview = useAutoFillStore((state) => state?.setTempReview);

  const handleSelectStar = (value, uuid) => {
    const index = formDataScenarioReview.rating_criterials_uuid.findIndex(
      (criteria) => criteria.rating_criteria_uuid === uuid
    );
    formDataScenarioReview.rating_criterials_uuid[index].stars = value;
    setFormDataScenarioReview({ ...formDataScenarioReview });
  };

  const handleEditStarAI = (value, uuid) => {
    const index = tempReview.rating_criterials_uuid.findIndex(
      (criteria) => criteria.rating_criteria_uuid === uuid
    );
    tempReview.rating_criterials_uuid[index].stars = value;
    setTempReview({ ...tempReview });
  };

  const showRetake = useMemo(() => Object.keys(responseRetake).length > 0, [responseRetake]);
  const isRetakeQualified = useMemo(() => {
    if (Object.keys(reviewScenarioSelected).length > 0) {
      if (reviewScenarioSelected?.response_retake?.rating_criterials.length > 0) return true;
      else return false;
    } else return false;
  }, [reviewScenarioSelected]);
  if (Object.keys(roleplayView).length === 0 || Object.keys(formDataScenarioReview).length < 2)
    return null;
  return (
    <div style={{ marginBottom: '0.75rem' }}>
      <RatingCriteriaTooltip />
      {/* Show criteria when submission is pending */}
      {!isRetakeQualified &&
        !filledWithAI &&
        (reviewScenarioSelected?.response?.rating_criterials.length === 0 || showRetake) &&
        formDataScenarioReview?.rating_criterials_uuid.map((criteria) => {
          return (
            <div
              style={{ display: 'flex', justifyContent: 'space-between' }}
              key={criteria.rating_criteria_uuid}
            >
              <p className="heading6" style={{ marginTop: '0.25rem' }}>
                {criteria.name}
              </p>

              <Rating
                name={criteria.rating_criteria_uuid}
                value={criteria.stars}
                onChange={(event, newValue) => {
                  handleSelectStar(newValue, criteria.rating_criteria_uuid);
                }}
              />
            </div>
          );
        })}

      {/* Show stars when filled with AI */}
      {!isRetakeQualified &&
        filledWithAI &&
        !startEditAI &&
        (reviewScenarioSelected?.response?.rating_criterials.length === 0 || showRetake) &&
        formDataScenarioReview?.rating_criterials_uuid.map((criteria) => {
          return (
            <div
              style={{ display: 'flex', justifyContent: 'space-between' }}
              key={criteria.rating_criteria_uuid}
            >
              <p className="heading6" style={{ marginTop: '0.25rem' }}>
                {criteria.name}
              </p>
              {statusAI === 'SUCCEEDED' ? (
                <Rating name={criteria.rating_criteria_uuid} value={criteria.stars} readOnly />
              ) : (
                <Rating name={criteria.rating_criteria_uuid} value={0} readOnly />
              )}
            </div>
          );
        })}

      {/* Show stars when filled with AI and you want Edit */}
      {!isRetakeQualified &&
        filledWithAI &&
        startEditAI &&
        (reviewScenarioSelected?.response?.rating_criterials.length === 0 || showRetake) &&
        tempReview?.rating_criterials_uuid.map((x) => {
          return (
            <div
              style={{ display: 'flex', justifyContent: 'space-between' }}
              key={`IA- ${x.rating_criteria_uuid}`}
            >
              <p className="heading6" style={{ marginTop: '0.25rem' }}>
                {x.name}
              </p>

              <Rating
                name={x.rating_criteria_uuid}
                value={x.stars}
                onChange={(event, newValue) => {
                  handleEditStarAI(newValue, x.rating_criteria_uuid);
                }}
              />
            </div>
          );
        })}

      {/* Show stars when criteria is already submitted */}
      {reviewScenarioSelected?.response?.rating_criterials.length !== 0 &&
        !showRetake &&
        reviewScenarioSelected?.response?.rating_criterials.map((criteria) => {
          return (
            <div
              style={{ display: 'flex', justifyContent: 'space-between' }}
              key={criteria.rating_criteria.uuid}
            >
              <p className="heading6" style={{ marginTop: '0.25rem' }}>
                {criteria.rating_criteria.name}
              </p>
              <Rating name={criteria.rating_criteria_uuid} value={criteria.stars} readOnly />
            </div>
          );
        })}
      {/* Show stars when 2 attempt selected and is qualified */}
      {showRetake &&
        isRetakeQualified &&
        responseRetake?.rating_criterials.map((criteria) => {
          return (
            <div
              style={{ display: 'flex', justifyContent: 'space-between' }}
              key={criteria.rating_criteria.uuid}
            >
              <p className="heading6" style={{ marginTop: '0.25rem' }}>
                {criteria.rating_criteria.name}
              </p>
              <Rating name={criteria.rating_criteria_uuid} value={criteria.stars} readOnly />
            </div>
          );
        })}
    </div>
  );
};

export default DescriptionRatingCriteria;
