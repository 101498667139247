import axiosInstance from './httpInterceptor';
import axiosIAInstance from './aiHttpInterceptor';

export const globalGetService = (url, params) => {
  return new Promise(function (resolve, reject) {
    axiosInstance({
      method: 'GET',
      url: url,
      params: params,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const globalPostService = (url, data) => {
  return new Promise(function (resolve, reject) {
    axiosInstance({
      method: 'POST',
      url: url,
      data: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const globalPutService = (url, data) => {
  return new Promise(function (resolve, reject) {
    axiosInstance({
      method: 'PUT',
      url: url,
      data: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const globalExportService = (url, queryParams = {}) => {
  return new Promise(function (resolve, reject) {
    axiosInstance({
      method: 'GET',
      url: url,
      responseType: 'blob',
      params: queryParams,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        console.err(err, 'error');
        reject(err);
      });
  });
};
export const globalDeleteService = (url, data) => {
  return new Promise(function (resolve, reject) {
    axiosInstance({
      method: 'DELETE',
      url: url,
      data: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const API = (function () {
  const success = (res) => res?.statusCode >= 200 && res?.statusCode < 300;

  const post = async (url, body, config) => {
    const response = await axiosInstance.post(url, body, config);
    if (success(response)) return response;
    throw new Error(response.message);
  };
  const del = async (url, body, config) => {
    const response = await axiosInstance.delete(url, body, config);
    if (success(response)) return response;
    throw new Error(response.message);
  };
  const put = async (url, body, config) => {
    const response = await axiosInstance.put(url, body, config);
    if (success(response)) return response;
    throw new Error(response.message);
  };
  const get = async (url, config) => {
    const response = await axiosInstance.get(url, config);
    if (success(response)) return response;
    throw new Error(response.message);
  };
  return { post, get, put, del };
})();

export const IA_API = (function () {
  const success = (res) => res.hasOwnProperty('message');

  const post = async (url, body, config) => {
    const response = await axiosIAInstance.post(url, body, config);
    if (success(response)) return response;
    throw new Error(response.error);
  };

  const get = async (url, config) => {
    const response = await axiosIAInstance.get(url, config);
    if (response) return response;
    throw new Error('Empty response');
  };
  return { post, get };
})();
