import { Tooltip, withStyles } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import classes from './Autorating.module.scss';
import AIFeedback from 'shared_ui_components/AIFeatures/AIFeedback/AIFeedback';
import { TextArea } from 'shared_ui_components';
import { useAutoFillStore } from './AutoFill.store';

const FeedbackAI = ({ ai_feedback = '', ai_feedback_status }) => {
  const startEditAI = useAutoFillStore((state) => state?.startEditAI);
  const tempReview = useAutoFillStore((state) => state?.tempReview);
  const setTempReview = useAutoFillStore((state) => state?.setTempReview);

  const handleChangeEditAI = (value) => {
    setTempReview({ ...tempReview, comment: value });
  };

  const TooltipStyled = withStyles(() => ({
    tooltip: {
      backgroundColor: 'black',
      color: 'white',
    },
    arrow: {
      color: 'black',
    },
  }))(Tooltip);
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
      <div className={classes.container}>
        <div>Feedback</div>
        <TooltipStyled title="Rating and feedback has been generated by AI." arrow placement="top">
          <HelpOutline style={{ fontSize: '1rem' }} />
        </TooltipStyled>
      </div>
      {startEditAI ? (
        <TextArea
          rows={3}
          value={tempReview.comment}
          onChange={(e) => handleChangeEditAI(e.target.value)}
        />
      ) : (
        <AIFeedback ai_feedback={ai_feedback} ai_feedback_status={ai_feedback_status} />
      )}
    </div>
  );
};

export default FeedbackAI;
