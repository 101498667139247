import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Grid, List, ListItem, Menu, MenuItem } from '@material-ui/core';
import { imageBasePath } from '../../../constants';
import styles from '../../../assets/styles/onboarding.module.scss';
import {
  CustomLink,
  CustomCheckbox,
  FileViewer,
  ReactHtmlParser,
} from '../../../shared_ui_components';
import Comments from './Comments';
import { format, parseISO } from 'date-fns';
import { getContentTypeImg, TooltipCustom, getContentTypeURL } from '../../../utils';
import { FeatureFlag } from 'controllers/_exports';
import { optionsRecurring, optionsFrequency } from 'constants';
import calendar from 'assets/calendar.svg';
import { ConfirmationDialog } from 'shared_elements';
import VideoPlayer from 'shared_ui_components/VideoPlayer/VideoPlayer';
import AISummary from 'shared_ui_components/AIFeatures/AISummary/AISummary';

export default function UploadedFilePreview(props) {
  const audioEl = useRef(null);
  const commentsEl = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const [playAudio, playAudioSet] = useState(false);
  const [autoplay, autoplaySet] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const {
    index,
    activeStep,
    isAdmin,
    updateActiveStep,
    page = 'training',
    role,
    isPublished,
    handleMarkAsRead,
  } = props;

  const handleClick = (event, key) => {
    setAnchorEl(event.currentTarget);
    setCurrentIndex(key);
  };

  const viewFile = (content) => {
    const win = window.open(content, '_blank');
    win.focus();
    handleReset();
    handleStart();
  };

  const handleAudioPlay = () => {
    playAudioSet(true);
    autoplaySet(true);
    setTimeout(() => {
      audioEl.current?.play();
    }, 1000);
    handleReset();
    handleStart();
  };

  useEffect(() => {
    if (playAudio) {
      handleAudioPlay();
    }
  }, [activeStep.content]);

  useEffect(() => {
    playAudioSet(false);
    autoplaySet(false);
  }, [activeStep.uuid]);

  const checkIsOwner = (user) => {
    const userInfo =
      localStorage.getItem('userInfo') && JSON.parse(localStorage.getItem('userInfo')).user;
    if (userInfo && userInfo?.uuid === user?.uuid) {
      return true;
    } else {
      if (page == 'training' && role && ['primary_admin', 'admin'].includes(role)) {
        return true;
      } else {
        return false;
      }
    }
  };

  const scrollToComments = () => {
    // commentsEl.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    window.scrollTo({ behavior: 'smooth', top: commentsEl.current.offsetTop - 80 });
  };

  let contentType = getContentTypeURL(activeStep.content);

  const hideMenu = useMemo(
    () => page === 'training' && activeStep?.disable_edition,
    [page, activeStep]
  );
  const [isActive, setIsActive] = useState(false);
  const [isPaused, setIsPaused] = useState(true);

  useEffect(() => {
    let interval = null;
    if (isActive && isPaused === false) {
      interval = setInterval(() => {
        if (props?.setTime && typeof props.setTime === 'function')
          props.setTime((time) => time + 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isActive, isPaused, props]);

  const handleStart = () => {
    setIsActive(true);
    setIsPaused(false);
  };

  const handleReset = () => {
    setIsActive(false);
    if (props?.setTime && typeof props.setTime === 'function') props.setTime(0);
  };

  const handlePause = () => {
    setIsPaused(true);
  };

  const handleResume = () => {
    setIsPaused(false);
  };
  useMemo(() => {
    if (activeStep?.content_read === 1) {
      handleReset();
      handleStart();
    }
  }, [activeStep]);

  useEffect(() => {
    if (
      handleMarkAsRead &&
      typeof handleMarkAsRead === 'function' &&
      activeStep?.input_type === 2 &&
      activeStep?.content_read !== 1
    )
      handleMarkAsRead(activeStep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  return (
    <>
      <ConfirmationDialog
        open={props.showModal}
        title={
          <>
            Are you sure you want to mark "{<strong>{activeStep?.name}</strong>}" content as
            complete?" <br />
          </>
        }
        description={`Suggested duration is ${
          activeStep?.suggest_duration / 60
        } min and you are completing it in ${Math.floor(props.time / 60)} ${
          Math.floor(props.time / 60) === 1 ? 'minute' : 'minutes'
        }`}
        acceptText="Mark as complete"
        rejectText="Continue"
        isDelete={false}
        image={imageBasePath + 'training_clock.svg'}
        handleClose={() => {
          props.setShowModal(false);
          setDeleteLoader(false);
        }}
        handleSubmit={() => props.handleMarkAsComplete(activeStep.content_type)}
        loader={deleteLoader}
      />
      <div className={styles.uploadedFilePreviewWrapper}>
        {isAdmin ? (
          <div className={styles.topBlkWrapper}>
            <ContentInformation activeStep={activeStep} />
            {hideMenu ? null : (
              <div className={styles.moreBlk + ' textRight'}>
                {page == 'onboarding' ||
                checkIsOwner(activeStep.created_by) ||
                role == 'sales_lead' ? (
                  <span className={styles.more}>
                    <a
                      aria-controls={'simple-menu-' + index}
                      className={'more'}
                      aria-haspopup="true"
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        handleClick(e, index);
                      }}
                    >
                      <img
                        className="more"
                        src={imageBasePath + 'more.svg'}
                        alt="More"
                        style={{ verticalAlign: 'middle' }}
                      />
                    </a>
                  </span>
                ) : null}
                <Menu
                  id={'simple-menu-' + index}
                  anchorEl={anchorEl}
                  keepMounted
                  open={currentIndex == index && Boolean(anchorEl) ? true : false}
                  onClose={(e) => {
                    setAnchorEl(null);
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  className="moreMenu moreMenuHoirzontal"
                >
                  {page == 'onboarding' ? (
                    activeStep.status === 1 &&
                    ('disable_edition' in activeStep ? !activeStep.disable_edition : true) &&
                    (activeStep.is_predefined || checkIsOwner(activeStep.created_by)) ? (
                      <MenuItem
                        onClick={(e) => {
                          setAnchorEl(null);
                          props.handleEditContent();
                        }}
                      >
                        <img src={imageBasePath + 'edit.svg'} alt="Edit" />
                        <span className="supportText">Edit</span>
                      </MenuItem>
                    ) : null
                  ) : (
                    <MenuItem
                      onClick={(e) => {
                        setAnchorEl(null);
                        props.handleEditContent();
                      }}
                    >
                      <img src={imageBasePath + 'edit.svg'} alt="Edit" />
                      <span className="supportText">Edit</span>
                    </MenuItem>
                  )}
                  {page == 'onboarding' ? (
                    <MenuItem
                      onClick={(e) => {
                        setAnchorEl(null);
                        props.handleEnabelDisable();
                      }}
                    >
                      {activeStep.status === 0 ? (
                        <img src={imageBasePath + 'check_grey3.svg'} alt="Disable" />
                      ) : (
                        <img src={imageBasePath + 'disable.svg'} alt="Disable" />
                      )}
                      <span className="supportText">
                        {activeStep.status === 0 ? `Enable` : `Disable`}
                      </span>
                    </MenuItem>
                  ) : null}
                  {page == 'onboarding' ? (
                    activeStep.is_predefined || !checkIsOwner(activeStep.created_by) ? null : (
                      <MenuItem
                        className="deleteAction"
                        onClick={(e) => {
                          setAnchorEl(null);
                          props.handleDeleteCustomStep();
                        }}
                      >
                        <img src={imageBasePath + 'delete_red.svg'} alt="Disable" />
                        <span className="supportText">Delete</span>
                      </MenuItem>
                    )
                  ) : checkIsOwner(activeStep.created_by) ? (
                    <MenuItem
                      className="deleteAction"
                      onClick={(e) => {
                        setAnchorEl(null);
                        props.handleDeleteCustomStep();
                      }}
                    >
                      <img src={imageBasePath + 'delete_red.svg'} alt="Disable" />
                      <span className="supportText">Delete</span>
                    </MenuItem>
                  ) : null}
                </Menu>
              </div>
            )}
          </div>
        ) : (
          <div className={`${styles.topBlkWrapper} ${styles.markCompleteWrapper}`}>
            <ContentInformation activeStep={activeStep} />
            {/* {!activeStep.content_read && contentType.id !== 1 ?  */}
            <div
              className={`${styles.markCompleteBlk} ${
                activeStep.completed_at ? styles.disabled : ''
              } ${
                !activeStep.content_read && activeStep.input_type !== 2 ? styles.contentNotRead : ''
              }`}
            >
              {!activeStep.content_read &&
              activeStep.input_type !== 2 &&
              (contentType.id !== 5959 || activeStep.input_type === 3) ? (
                <TooltipCustom
                  title="Please go through the content to mark it as complete"
                  arrow
                  placement="top"
                >
                  <div>
                    <CustomCheckbox
                      disabled
                      label={`Mark as complete`}
                      value={activeStep.completed_at ? true : false}
                    />
                  </div>
                </TooltipCustom>
              ) : FeatureFlag.enabled('TRACK_TIME') ? (
                <TooltipCustom
                  title={`Sugested time of completion is ${Math.round(
                    activeStep?.suggest_duration / 60
                  )} minutes`}
                  arrow
                  placement="top"
                >
                  <div>
                    <CustomCheckbox
                      label={activeStep.completed_at ? `Marked as complete` : `Mark as complete`}
                      type=""
                      value={activeStep.completed_at ? true : false}
                      onChange={() => {
                        if (activeStep?.suggest_duration <= props.time)
                          props.handleMarkAsComplete(activeStep.content_type);
                        else props.setShowModal(true);
                      }}
                    />
                  </div>
                </TooltipCustom>
              ) : (
                <CustomCheckbox
                  label={activeStep.completed_at ? `Marked as complete` : `Mark as complete`}
                  type=""
                  value={activeStep.completed_at ? true : false}
                  onChange={() => props.handleMarkAsComplete(activeStep.content_type)}
                />
              )}
            </div>
          </div>
        )}

        {contentType.id === 1 && activeStep.input_type != 3 ? (
          <div className={styles.vedioPreview}>
            <div
              className={`${styles.videoWrapper} ${
                activeStep.input_type == 2 &&
                activeStep.content &&
                activeStep.content.includes('height')
                  ? styles.defaultIframeWrapper
                  : styles.defaultIframeWrapper
              }`}
            >
              {getContentTypeURL(activeStep.content)?.id === 1 && activeStep.input_type !== 2 ? (
                <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                  {!FeatureFlag.enabled('VIDEO_ISSUE') ? (
                    <video controls key={activeStep.updated_at}>
                      <source src={activeStep.content} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  ) : (
                    <VideoPlayer
                      src={activeStep.content}
                      onStart={() => {
                        handleReset();
                        handleStart();
                        handleMarkAsRead(activeStep);
                      }}
                      onPause={() => {
                        handlePause();
                      }}
                      onResume={() => {
                        handleResume();
                      }}
                    />
                  )}
                </div>
              ) : activeStep.input_type == 2 ? (
                <ReactHtmlParser content={activeStep.content} />
              ) : null}
            </div>
            <Grid
              container
              direction="row"
              alignItems="center"
              spacing={0}
              className={styles.contentRow}
            >
              <Grid item lg={8} md={8} sm={8} xs={8}>
                <h3 className="heading4">{activeStep.name}</h3>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={4}>
                {isAdmin ? (
                  isPublished ? (
                    <FeedbackInformation
                      activeStep={activeStep}
                      handleLikeDislike={props.handleLikeDislike}
                      scrollToComments={scrollToComments}
                    />
                  ) : null
                ) : (
                  <FeedbackInformation
                    activeStep={activeStep}
                    handleLikeDislike={props.handleLikeDislike}
                    scrollToComments={scrollToComments}
                  />
                )}
              </Grid>
            </Grid>
            <AISummary
              ai_summary={activeStep?.ai_summary}
              ai_summary_status={activeStep?.ai_summary_status}
            />
            <ContentDescription description={activeStep.description} limit={470} />
            {activeStep.tags && activeStep.tags.length ? <TagsList tags={activeStep.tags} /> : null}
            {/* <ContentInformation activeStep={activeStep} /> */}
          </div>
        ) : activeStep.content_type === 0 ||
          activeStep.content_size === null ||
          activeStep.content_type === 2 ||
          Math.trunc(activeStep.content_size / (1024 * 1024)) >= 25 ||
          (activeStep.content_size === 0 && activeStep.content_type === 6) ? (
          <div className={styles.filePreview}>
            <Grid container direction="row" spacing={0} className={styles.filePreviewRow}>
              <Grid item lg={7} md={7} sm={12} xs={12} className={styles.leftBlk}>
                <div className={styles.contentWrapper}>
                  <h3 className="heading4">{activeStep.name}</h3>
                  <ContentDescription description={activeStep.description} limit={250} />
                  {activeStep.tags && activeStep.tags.length ? (
                    <TagsList tags={activeStep.tags} />
                  ) : null}
                  {/* <ContentInformation activeStep={activeStep} /> */}
                  <div
                    style={{
                      display: 'flex',
                      'align-items': 'center',
                      'justify-content': 'flex-start',
                    }}
                  >
                    {!playAudio ? (
                      <CustomLink
                        className="primaryBtn"
                        linktext={
                          contentType.id === 2
                            ? `Play`
                            : `${
                                contentType.id === 0 ||
                                activeStep.content_type === 0 ||
                                activeStep.content.includes('.txt') ||
                                activeStep.content.includes('.jpg') ||
                                activeStep.content.includes('.jpeg') ||
                                activeStep.content.includes('.png') ||
                                activeStep.content.includes('.svg') ||
                                activeStep.content.includes('.mp3') ||
                                activeStep.content.includes('.csv') ||
                                activeStep.content.includes('.rtf')
                                  ? `Click to go`
                                  : `Download`
                              }`
                        }
                        onClick={
                          contentType.id === 2
                            ? (e) => {
                                handleAudioPlay();
                                handleMarkAsRead
                                  ? handleMarkAsRead(activeStep)
                                  : e.preventDefault();
                              }
                            : (e) => {
                                viewFile(activeStep.content);
                                handleMarkAsRead
                                  ? handleMarkAsRead(activeStep)
                                  : e.preventDefault();
                              }
                        }
                      />
                    ) : null}
                    {playAudio ? (
                      <audio
                        controls
                        autoplay={autoplay}
                        className="audio-element"
                        controlsList="nodownload"
                        ref={audioEl}
                        key={activeStep.content}
                      >
                        <source src={activeStep.content} type="audio/ogg" />
                        <source src={activeStep.content} type="audio/mpeg" />
                        Your browser does not support the audio element.
                      </audio>
                    ) : null}
                  </div>
                </div>
              </Grid>
              <Grid item lg={5} md={5} sm={12} xs={12} className={styles.rightBlk}>
                <div className={styles.imageWrapper}>
                  {activeStep.content.match(/^http[^\?]*.(jpg|jpeg|png)(\?(.*))?$/gim) != null ? (
                    <img src={activeStep.content} alt="Uploaded image" />
                  ) : (
                    getContentTypeImg(contentType.id, activeStep.content)
                  )}
                </div>
                {isAdmin ? (
                  isPublished ? (
                    <FeedbackInformation
                      activeStep={activeStep}
                      handleLikeDislike={props.handleLikeDislike}
                      scrollToComments={scrollToComments}
                    />
                  ) : null
                ) : (
                  <FeedbackInformation
                    activeStep={activeStep}
                    handleLikeDislike={props.handleLikeDislike}
                    scrollToComments={scrollToComments}
                  />
                )}
              </Grid>
            </Grid>
            <AISummary
              ai_summary={activeStep?.ai_summary}
              ai_summary_status={activeStep?.ai_summary_status}
            />
          </div>
        ) : (
          <div className={styles.filePreview}>
            <Grid container direction="row" spacing={0} className={styles.filePreviewRow}>
              <Grid item lg={7} md={7} sm={12} xs={12} className={styles.leftBlk}>
                <div className={styles.contentWrapper}>
                  <h3 className="heading4">{activeStep.name}</h3>
                  <ContentDescription description={activeStep.description} limit={250} />

                  {activeStep.tags && activeStep.tags.length ? (
                    <TagsList tags={activeStep.tags} />
                  ) : null}
                  {/* <ContentInformation activeStep={activeStep} /> */}
                </div>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12} className={styles.rightBlk}>
                {activeStep.input_type === 2 && (
                  <div
                    className={`${styles.videoWrapper} ${
                      activeStep.input_type == 2 &&
                      activeStep.content &&
                      activeStep.content.includes('height')
                        ? styles.defaultIframeWrapper
                        : styles.defaultIframeWrapper
                    }`}
                  >
                    <ReactHtmlParser content={activeStep.content} />
                  </div>
                )}
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} className={styles.rightBlk}>
                <FileViewer activeStep={activeStep} handleMarkAsRead={handleMarkAsRead} />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} className={styles.rightBlk}>
                <div className={styles.imageWrapper}>
                  {activeStep.content.match(/^http[^\?]*.(jpg|jpeg|png|svg)(\?(.*))?$/gim) !=
                  null ? (
                    <object
                      onLoad={(e) => {
                        handleMarkAsRead ? handleMarkAsRead(activeStep) : e.preventDefault();
                      }}
                      data={activeStep.content}
                      type="image/svg+xml"
                    >
                      <img
                        src={activeStep.content}
                        alt="Uploaded file"
                        style={{ height: '65vh', objectFit: 'contain' }}
                      />
                    </object>
                  ) : null}
                </div>
                {isAdmin ? (
                  isPublished ? (
                    <FeedbackInformation
                      activeStep={activeStep}
                      handleLikeDislike={props.handleLikeDislike}
                      scrollToComments={scrollToComments}
                    />
                  ) : null
                ) : (
                  <FeedbackInformation
                    activeStep={activeStep}
                    handleLikeDislike={props.handleLikeDislike}
                    scrollToComments={scrollToComments}
                  />
                )}
              </Grid>
            </Grid>
            <AISummary
              ai_summary={activeStep?.ai_summary}
              ai_summary_status={activeStep?.ai_summary_status}
            />
          </div>
        )}
        {isAdmin ? (
          isPublished ? (
            <div ref={commentsEl}>
              <Comments activeStep={activeStep} updateActiveStep={updateActiveStep} page={page} />
            </div>
          ) : null
        ) : (
          <div ref={commentsEl}>
            <Comments activeStep={activeStep} updateActiveStep={updateActiveStep} page={page} />
          </div>
        )}
      </div>
    </>
  );
}

function FeedbackInformation(props) {
  const { activeStep, scrollToComments } = props;
  return (
    <List className={styles.engagementList + ' listInline textRight engagementList'}>
      <ListItem className="listInlineItem" onClick={() => scrollToComments()}>
        <img src={imageBasePath + 'comment_default.svg'} alt="Comment" />
        <span className="paragraph">{activeStep.comments?.length}</span>
      </ListItem>
      <ListItem className="listInlineItem" onClick={() => props.handleLikeDislike(1)}>
        {activeStep.like_status === 1 ? (
          <img src={imageBasePath + 'like_active.svg'} alt="Like" />
        ) : (
          <img src={imageBasePath + 'like_default.svg'} alt="Like" />
        )}
        <span className="paragraph">{activeStep.likes}</span>
      </ListItem>
      <ListItem className="listInlineItem" onClick={() => props.handleLikeDislike(2)}>
        {activeStep.like_status === 2 ? (
          <img src={imageBasePath + 'dislike_active.svg'} alt="Dislike" />
        ) : (
          <img src={imageBasePath + 'dislike_default.svg'} alt="Dislike" />
        )}
        <span className="paragraph">{activeStep.dislikes}</span>
      </ListItem>
    </List>
  );
}

function TagsList(props) {
  const { tags } = props;
  return (
    <List className={styles.tagList + ' listInline tagList'}>
      {tags.map((item, index) => (
        <ListItem className="listInlineItem tagListItem" key={item.id}>
          <span className="supportText">
            {item.name}
            <img src={imageBasePath + 'tag_close.svg'} alt="Remove" className="hidden" />
          </span>
        </ListItem>
      ))}
    </List>
  );
}

function ContentInformation(props) {
  const { activeStep } = props;
  return (
    <>
      {!FeatureFlag.enabled('SCHEDULE_TRAINING') ? (
        <List className={styles.optionalList + ' listInline optionalList'}>
          <ListItem className="listInlineItem tagListItem">
            {activeStep.is_required ? (
              <span className={styles.mandatory + ' supportText'}>
                <img
                  src={imageBasePath + 'success_outlined.svg'}
                  className={styles.infoImg}
                  alt="icon"
                />
                Mandatory
              </span>
            ) : (
              <span className={styles.optional + ' supportText'}>
                <img
                  src={imageBasePath + 'optional_info.svg'}
                  className={styles.infoImg}
                  alt="icon"
                />
                Optional
              </span>
            )}
          </ListItem>
          <ListItem className="listInlineItem tagListItem">
            <span className="supportText">
              {`Updated on : ${
                activeStep?.updated_at
                  ? format(parseISO(activeStep?.updated_at), 'do MMM yyyy')
                  : '--'
              }`}
            </span>
          </ListItem>
        </List>
      ) : (
        <List
          className={styles.optionalList + ' listInline optionalList'}
          style={{
            display: 'flex',
            gap: '0.5rem',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <ListItem className="listInlineItem tagListItem">
              <span className="supportText">
                {`Updated on : ${
                  activeStep?.updated_at
                    ? format(parseISO(activeStep?.updated_at), 'do MMM yyyy')
                    : '--'
                }`}
              </span>
            </ListItem>
          </div>
          <div>
            <ListItem
              className="listInlineItem tagListItem"
              style={{ display: 'flex', gap: '1rem' }}
            >
              {activeStep?.is_retake && (
                <>
                  <span className="supportText">
                    {`Best by : ${
                      activeStep?.next_date
                        ? format(parseISO(activeStep?.next_date), 'do MMM yyyy')
                        : '--'
                    }`}
                  </span>
                  <span className={styles.calendar + ' supportText'}>
                    <img src={calendar} className={styles.infoImg} alt="icon" />
                    {activeStep?.interval >= 6
                      ? 'Every ' +
                        activeStep?.frequency +
                        ' ' +
                        optionsFrequency.find((e) => e.value === activeStep?.interval).label
                      : optionsRecurring.find((e) => e.value === activeStep?.interval).label}
                  </span>
                </>
              )}

              {activeStep.is_required ? (
                <span className={styles.mandatory + ' supportText'}>
                  <img
                    src={imageBasePath + 'success_outlined.svg'}
                    className={styles.infoImg}
                    alt="icon"
                  />
                  Mandatory
                </span>
              ) : (
                <span className={styles.optional + ' supportText'}>
                  <img
                    src={imageBasePath + 'optional_info.svg'}
                    className={styles.infoImg}
                    alt="icon"
                  />
                  Optional
                </span>
              )}
            </ListItem>
          </div>
        </List>
      )}
    </>
  );
}

function ContentDescription(props) {
  const { description, limit } = props;
  const [show, setShow] = useState(false);
  return (
    <>
      {show ? (
        <p className={styles.description + ' paragraph'}>{description}</p>
      ) : (
        <p className={styles.description + ' paragraph'}>
          {description && description.length > limit
            ? `${description.slice(0, limit - 20)} ...`
            : description}
        </p>
      )}
      {description && description.length > limit ? (
        <div className={styles.viewMore + ' textCenter ' + `${show ? styles.viewMoreClear : ' '}`}>
          <CustomLink
            className="linkBtn"
            linktext={show ? 'View Less' : 'View More'}
            onClick={() => setShow(!show)}
          />
        </div>
      ) : null}
    </>
  );
}
