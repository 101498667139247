import React from 'react';
import { FormControl, InputLabel } from '@material-ui/core';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { reactSelectStyles } from '../constants';
import PropTypes from 'prop-types';

export default function InputSelect(props) {
  const defaultOptions = {
    closeMenuOnSelect: true,
    maxMenuHeight: 200,
    className: 'reactSelect',
    components: { DropdownIndicator: ExpandMoreIcon },
    styles: reactSelectStyles({
      errorValues: props?.errorValues || [],
      optionBackground: props?.optionBackground || '',
    }),
  };

  return (
    <div className={`inputGroup `}>
      <FormControl
        className="formControl"
        error={props.error ? true : false}
        required={props.required}
        disabled={props.disabled}
      >
        {props.label ? (
          <InputLabel shrink id="actionLabel">
            {props.label}
          </InputLabel>
        ) : null}
        {props.isCreatable ? (
          <CreatableSelect {...defaultOptions} {...props} />
        ) : (
          <ReactSelect {...defaultOptions} {...props} isDisabled={props.disabled} />
        )}
        {props.error && typeof props.error === 'string' ? (
          <h6 className="errorMsg">{props.error}</h6>
        ) : null}
      </FormControl>
    </div>
  );
}

InputSelect.propTypes = {
  label: PropTypes.string,
  isCreatable: PropTypes.bool,
};

InputSelect.defaultProps = {
  label: '',
  isCreatable: false,
};
