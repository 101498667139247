import { create } from 'zustand';

const initialState = {
  filledWithAI: false,
  startEditAI: false,
  saveEdition: false,
  statusAI: '',
  tempReview: { comment: '' },
  responseData: [],
  isError: false,
};
export const useAutoFillStore = create((set, get) => ({
  ...initialState,
  toggleFilledWithAI: () => set((state) => ({ filledWithAI: !state.filledWithAI })),
  toggleStartEditAI: () => set((state) => ({ startEditAI: !state.startEditAI })),
  toggleSaveEdition: () => set((state) => ({ saveEdition: !state.saveEdition })),
  //temp for formDataScenarioReview
  setTempReview: (tempReview = {}) => set(() => ({ tempReview })),
  setStatus: (status) => set(() => ({ statusAI: status })),
  setResponseData: (responseData = []) => set(() => ({ responseData })),
  toggleIsError: () => set((state) => ({ isError: !state?.isError })),
  reset: () => {
    set(initialState);
  },
}));
