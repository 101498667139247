import { getStore } from '../store/GlobalStore';
import { imageBasePath, contentTypes } from '../constants';
import {
  endOfDay,
  startOfDay,
  format,
  formatDistance,
  parseISO,
  startOfWeek,
  endOfWeek,
  subDays,
  startOfMonth,
  endOfMonth,
  startOfQuarter,
  endOfQuarter,
  differenceInDays,
  isMonday,
  previousFriday,
  previousSaturday,
  previousSunday,
} from 'date-fns';
import NumberFormat from 'react-number-format';
import { Tooltip, withStyles } from '@material-ui/core';

export const toastFlashMessage = (message, type = 'info', delay = 5000) => {
  const { dispatch } = getStore();
  dispatch({
    type: 'CLEAR_TOAST',
    payload: '',
  });
  setTimeout(function () {
    dispatch({
      type: 'SHOW_TOAST',
      payload: {
        message: message,
        toastType: type,
      },
    });
    setTimeout(function () {
      dispatch({
        type: 'HIDE_TOAST',
        payload: {},
      });
    }, delay);
  }, 200);
};

export const downloadFileType = (fileData, fileName, fileExtension) => {
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(fileData, fileName + fileExtension);
  } else {
    const url = window.URL.createObjectURL(new Blob([fileData]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName + fileExtension);
    document.body.appendChild(link);
    link.click();
  }
};

export const getChartCenterText = (value) => {
  const text = {
    id: 'text',
    beforeDraw: function (chart, a, b) {
      var width = chart.width,
        height = chart.height,
        ctx = chart.ctx;

      ctx.restore();
      var fontSize = (height / 114).toFixed(2);
      ctx.font = fontSize + 'em sans-serif';
      ctx.textBaseline = 'middle';

      var text = value,
        textX = Math.round((width - ctx.measureText(text).width) / 2),
        textY = height / 2;

      ctx.fillText(text, textX, textY);
      ctx.save();
    },
  };
  return text;
};

export function bytesToSize(a, b = 2) {
  if (0 === a) return '0 Bytes';
  const c = 0 > b ? 0 : b,
    d = Math.floor(Math.log(a) / Math.log(1024));
  return (
    parseFloat((a / Math.pow(1024, d)).toFixed(c)) +
    ' ' +
    ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d]
  );
}

export const getContentTypeImg = (contentType, value) => {
  switch (contentType) {
    case 0:
      return (
        <>
          <img src={imageBasePath + 'default_featured.png'} alt="text" />
        </>
      );
    case 1:
      return (
        <>
          <img src={imageBasePath + 'default_featured.png'} alt="text" />
        </>
      );
    case 2:
      return (
        <>
          <img src={imageBasePath + 'audio_featured.png'} alt="audio" />
        </>
      );
    case 3:
      return (
        <>
          <img src={imageBasePath + 'pdf_featured.png'} alt="pdf" />
        </>
      );
    case 4:
      return (
        <>
          <img src={imageBasePath + 'document_featured.png'} alt="document" />
        </>
      );
    case 5:
      return (
        <>
          <img src={imageBasePath + 'xls_featured.png'} alt="xls" />
        </>
      );
    case 6:
      return (
        <>
          <img src={imageBasePath + 'text_featured.png'} alt="text" />
        </>
      );
    case 7:
      return (
        <>
          <img src={imageBasePath + 'ppt_featured.png'} alt="text" />
        </>
      );
    case 8:
      return value && typeof value === 'string' ? (
        <>
          <object data={value} type="image/svg+xml">
            <img src={value} alt="" />
          </object>
        </>
      ) : (
        <img src={imageBasePath + 'default_featured_grey.png'} alt="" />
      );
    default:
      return <img src={imageBasePath + 'default_featured_grey.png'} alt="text" />;
  }
};

export const getContentTypeImgPulse = (contentType) => {
  switch (contentType) {
    case 0:
      return (
        <>
          <img
            src={imageBasePath + 'default_featured.png'}
            alt="text"
            style={{ maxWidth: '80px' }}
          />
        </>
      );
    case 1:
      return (
        <>
          <img
            src={imageBasePath + 'default_featured.png'}
            alt="text"
            style={{ maxWidth: '80px' }}
          />
        </>
      );
    case 2:
      return (
        <>
          <img
            src={imageBasePath + 'audio_featured.png'}
            alt="audio"
            style={{ maxWidth: '80px' }}
          />
        </>
      );
    case 3:
      return (
        <>
          <img src={imageBasePath + 'pdf_featured.png'} alt="pdf" style={{ maxWidth: '80px' }} />
        </>
      );
    case 4:
      return (
        <>
          <img
            src={imageBasePath + 'document_featured.png'}
            alt="document"
            style={{ maxWidth: '80px' }}
          />
        </>
      );
    case 5:
      return (
        <>
          <img src={imageBasePath + 'xls_featured.png'} alt="xls" style={{ maxWidth: '80px' }} />
        </>
      );
    case 6:
      return (
        <>
          <img src={imageBasePath + 'text_featured.png'} alt="text" style={{ maxWidth: '80px' }} />
        </>
      );
    case 7:
      return (
        <>
          <img src={imageBasePath + 'ppt_featured.png'} alt="text" style={{ maxWidth: '80px' }} />
        </>
      );
    default:
      return (
        <img
          src={imageBasePath + 'default_featured_grey.png'}
          alt="text"
          style={{ maxWidth: '80px' }}
        />
      );
  }
};

export const getContentTypeImgURL = (value) => {
  let val = value && value.trim().toLowerCase();
  let type = contentTypes.find((item) =>
    item.extensions.some((ext) => val.includes(ext.toLowerCase()))
  );
  return getContentTypeImg(type?.id ?? 0, value);
};

export const getContentTypeURL = (value) => {
  if (value) {
    let val = value && value.trim().toLowerCase();
    var contentType = contentTypes.find((item) =>
      item.extensions.some((ext) => val.includes(ext.toLowerCase()))
    );
    if (contentType) {
      return contentType;
    }
    contentType = contentTypes.find((item) =>
      item.platforms.some((pt) => val.includes(pt.toLowerCase()))
    );
    if (contentType) {
      return contentType;
    }
  }
  return contentTypes.find((content) => content.id == 0);
};

export const getContentTypeIcons = (contentType) => {
  switch (contentType) {
    case 0:
      return (
        <>
          <img src={imageBasePath + 'link.svg'} className={`defaultImg`} alt="Video" />
          <img src={imageBasePath + 'link_active.svg'} className={`activeImg`} alt="Video" />
        </>
      );
    case 1:
      return (
        <>
          <img src={imageBasePath + 'video.svg'} className={`defaultImg`} alt="Video" />
          <img src={imageBasePath + 'video_active.svg'} className={`activeImg`} alt="Video" />
        </>
      );
    case 2:
      return (
        <>
          <img src={imageBasePath + 'audio.svg'} className={`defaultImg`} alt="Audio" />
          <img src={imageBasePath + 'audio_active.svg'} className={`activeImg`} alt="Audio" />
        </>
      );
    case 3:
      return (
        <>
          <img src={imageBasePath + 'ppt.svg'} className={`defaultImg`} alt="PPT" />
          <img src={imageBasePath + 'ppt_active.svg'} className={`activeImg`} alt="PPT" />
        </>
      );
    case 4:
      return (
        <>
          <img src={imageBasePath + 'word.svg'} className={`defaultImg`} alt="Word" />
          <img src={imageBasePath + 'word_active.svg'} className={`activeImg`} alt="Word" />
        </>
      );
    case 5:
      return (
        <>
          <img src={imageBasePath + 'xls.svg'} className={`defaultImg`} alt="Xls" />
          <img src={imageBasePath + 'xls_active.svg'} className={`activeImg`} alt="Xls" />
        </>
      );
    case 6:
      return (
        <>
          <img src={imageBasePath + 'doc.svg'} className={`defaultImg`} alt="Doc" />
          <img src={imageBasePath + 'doc_active.svg'} className={`activeImg`} alt="Doc" />
        </>
      );
    case 7:
      return (
        <>
          <img src={imageBasePath + 'ppt.svg'} className={`defaultImg`} alt="Word" />
          <img src={imageBasePath + 'ppt_active.svg'} className={`activeImg`} alt="Word" />
        </>
      );
    case 8:
      return (
        <>
          <img src={imageBasePath + 'image.svg'} className={`defaultImg`} alt="" />
          <img src={imageBasePath + 'image_active.svg'} className={`activeImg`} alt="" />
        </>
      );
    default:
      return (
        <>
          <img src={imageBasePath + 'word.svg'} className={`defaultImg`} alt="Word" />
          <img src={imageBasePath + 'word_active.svg'} className={`activeImg`} alt="Word" />
        </>
      );
  }
};

export const fetchContentType = (value = '', callbackFun, inputType = '') => {
  let val = value && value.trim().toLowerCase();
  let type = contentTypes.find((item) =>
    item.extensions.some((ext) => val.includes(ext.toLowerCase()))
  );
  if (type) {
    callbackFun('content_type', type.id);
  } else if (inputType == 'insertLink') {
    callbackFun('content_type', 0);
  } else {
    let type = contentTypes.find((item) =>
      item.platforms.some((ext) => val.includes(ext.toLowerCase()))
    );
    if (type) {
      callbackFun('content_type', type.id);
    } else {
      callbackFun('content_type', 0);
    }
  }
};

export const isValidVideoLink = (link = '') => {
  let val = link && link.trim().toLocaleLowerCase();
  let contentType = contentTypes.find((item) => item.id === 1);
  let isValidExtension = contentType.extensions.some((ext) => val.includes(ext.toLowerCase()));
  let isValidPlatform = contentType.platforms.some((ext) => val.includes(ext.toLowerCase()));
  return isValidExtension || isValidPlatform;
};

export const isValidAudioLink = (link = '') => {
  let val = link && link.trim().toLocaleLowerCase();
  let contentType = contentTypes.find((item) => item.id === 2);
  let isValid = contentType.extensions.some((ext) => val.includes(ext.toLowerCase()));
  return isValid;
};

export const isValidUrl = (url = '') => {
  let pattern = new RegExp(
    '((http|https)://)(www.)?' +
      '[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]' +
      '{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)',
    'i'
  );
  if (pattern.test(url)) {
    return true;
  } else {
    return false;
  }
};

export const lastActivity = (date = new Date()) => {
  if (typeof date === 'string') {
    let newDate = formatDistance(parseISO(date), new Date());
    newDate = newDate.replace('about', '');
    newDate = newDate.replace('minutes', 'min');
    newDate = newDate.replace('minute', 'min');
    newDate = newDate.replace('less than', '');
    return `${newDate} ago`;
  } else {
    return '0 min ago';
  }
};

export const CustomNumberFormatter = (num) => {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
  }
  return num || 0;
};
// console.log(CustomNumberFormatter(-140995));

// export const CustomNumberFormatter = (num, digits = 2) => {
//   const lookup = [
//     { value: 1, symbol: "" },
//     { value: 1e3, symbol: "k" },
//     { value: 1e6, symbol: "M" },
//     { value: 1e9, symbol: "G" },
//     { value: 1e12, symbol: "T" },
//     { value: 1e15, symbol: "P" },
//     { value: 1e18, symbol: "E" }
//   ];
//   const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
//   var item = lookup.slice().reverse().find(function(item) {
//     return num >= item.value;
//   });
//   return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
// }

export const NumberFormatCustom = (props) => {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      // format={CustomNumberFormatter}
      thousandSeparator
      isNumericString
      allowNegative={false}
    />
  );
};

export const changeTimezone = (date) => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  var invdate = new Date(
    date.toLocaleString('en-US', {
      timeZone: userInfo?.user?.timezone,
    })
  );
  var diff = date.getTime() - invdate.getTime();
  return new Date(date.getTime() - diff);
};

export const getStartEndDateOfCheckin = (checkin) => {
  if (checkin && checkin.length) {
    let sgoal = checkin.find((cat) => !cat.is_organisation);
    const dateToCheck = changeTimezone(new Date());

    if (sgoal.frequency == 1) {
      const start = dateToCheck;
      const end = dateToCheck;
      return {
        start_date: start,
        end_date: end,
      };
    } else if (sgoal.frequency == 2) {
      const start = startOfWeek(dateToCheck, { weekStartsOn: 1 });
      const end = endOfWeek(dateToCheck, { weekStartsOn: 1 });
      return {
        start_date: start,
        end_date: end,
      };
    } else if (sgoal.frequency == 3) {
      const start = startOfMonth(dateToCheck);
      const end = endOfMonth(dateToCheck);
      return {
        start_date: start,
        end_date: end,
      };
    } else if (sgoal.frequency == 4) {
      const start = startOfQuarter(dateToCheck);
      const end = endOfQuarter(dateToCheck);
      return {
        start_date: start,
        end_date: end,
      };
    } else {
      return {
        start_date: '',
        end_date: '',
      };
    }
  }
  return {
    start_date: '',
    end_date: '',
  };
};

export const diffInDays = (startDate, endDate) => {
  return differenceInDays(startOfDay(new Date(startDate)), startOfDay(new Date(endDate)));
};

export const isMondayDay = (date) => {
  return isMonday(startOfDay(new Date(date)));
};

export const isPreviousFriday = (date) => {
  return previousFriday(startOfDay(new Date(date)));
};

export const isPreviousSaturday = (date) => {
  return previousSaturday(startOfDay(new Date(date)));
};

export const isPreviousSunday = (date) => {
  return previousSunday(startOfDay(new Date(date)));
};

// export const getCheckinType = (frequency, lastCheckin) => {
//   let dif = diffInDays(new Date(), lastCheckin);
//   switch (frequency) {
//     case 1:
//       return dif > 0 ? 0 : 1
//     case 2:
//       return dif > 7 ? 0 : 1
//     case 3:
//       return dif > 30 ? 0 : 1
//     case 4:
//       return dif > 90 ? 0 : 1
//     default:
//       return 0;
//   }
//   // 1 - Edit checkin and 0 - Checkin
// }
export const TooltipCustom = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#415766',
    color: '#fff',
    fontSize: 12,
    padding: '8px 16px',
    // margin: '5px 0'
  },
  arrow: {
    color: '#415766',
  },
}))(Tooltip);
export const TooltipCustomDash = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#0494FD',
    color: '#fff',
    fontSize: 12,
    padding: '8px 16px',
    // margin: '5px 0'
  },
  arrow: {
    color: '#0494FD',
  },
}))(Tooltip);

export const getTimezoneOptions = (timezones) => {
  if (timezones && Object.keys(timezones).length) {
    let options = [];
    Object.keys(timezones).forEach((key) => {
      options = [...options, ...[{ value: timezones[key].key, label: timezones[key].label }]];
    });
    return options;
  } else {
    return [];
  }
};

export const getSizeInMb = (sizeInBytes) => {
  let sizeInMB = (sizeInBytes / (1024 * 1024)).toFixed(2);
  return sizeInMB;
};

export function formatMegaBytes(megabytes, decimals = 0) {
  let bytes = megabytes * 1048576;
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
