import React, { useState, useMemo } from 'react';
import styles from '../AIFeatures.module.scss';
import { imageBasePath } from 'constants';
import Organization from 'infrastructure/Organization';
import classNames from 'classnames';
import { Skeleton } from '@material-ui/lab';

const STATUS = {
  IN_PROGRESS: 1,
  NOT_SUPPORTED: 2,
  SUCCESS: 3,
};

const AISummary = ({ ai_summary = '', ai_summary_status }) => {
  const [hovered, setHovered] = useState(false);

  const title = useMemo(() => {
    if (ai_summary_status === STATUS.NOT_SUPPORTED) return 'AI-Powered Summary is not available.';
    return 'AI-Powered Summary';
  }, [ai_summary_status]);

  const text = useMemo(() => {
    if (ai_summary_status === STATUS.IN_PROGRESS)
      return "We're processing the information to provide you with the best summary.";
    if (ai_summary_status === STATUS.NOT_SUPPORTED)
      return "File isn't supported or content cannot be read.";
    return ai_summary;
  }, [ai_summary_status, ai_summary]);

  const skeletons = useMemo(() => {
    if (ai_summary_status !== STATUS.IN_PROGRESS) return [];
    const widths = [30, 35, 40, 50, 60, 70];
    const aux = [];
    for (let index = 0; index < 45; index++)
      aux.push(widths[Math.floor(Math.random() * widths.length)]);
    return aux;
  }, [ai_summary_status]);

  if (!Boolean(Organization.getSelectedOrg()?.ai_integration)) return null;
  return (
    <div
      className={classNames([
        ai_summary_status === STATUS.SUCCESS && styles.success,
        ai_summary_status === STATUS.IN_PROGRESS && styles.loading,
        ai_summary_status === STATUS.NOT_SUPPORTED && styles.error,
      ])}
    >
      <p className={styles.title}>{title}</p>

      {ai_summary_status === STATUS.IN_PROGRESS ? (
        <div className={styles.loadingContainer}>
          {skeletons.map((width, i) => (
            <Skeleton key={i} variant="text" width={`${width}px`} height={12} />
          ))}
        </div>
      ) : (
        <p className={styles.text}>{text}</p>
      )}

      {ai_summary_status !== STATUS.NOT_SUPPORTED && (
        <div className={styles.infoContainer}>
          <img
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            alt="info"
            src={imageBasePath + 'info_darkgrey.svg'}
          />
          {ai_summary_status === STATUS.IN_PROGRESS ? (
            <p style={{ opacity: 1 }}>{text}</p>
          ) : (
            <p style={{ opacity: hovered ? 1 : 0 }}>
              This summary has been generated using AI. It is recommended to consult the original
              text for a complete and accurate understanding of the content.
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default AISummary;
