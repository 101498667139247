import React, { useState, useEffect, useMemo } from 'react';
import SubmitRoleplayCard from './SubmitRoleplayCard';
import { useRoleplayStore } from 'features/roleplay/Roleplay.store';
import { CustomButton } from 'shared_ui_components';
import styles from './SubmitRoleplay.module.scss';
import {
  useSubmitRoleplay,
  useSubmitRoleplayForProcessingWithAI,
} from 'features/roleplay/Roleplay.queries';
import { useLocation, useHistory } from 'react-router-dom';
import Organization from 'infrastructure/Organization';
import { FeatureFlag } from 'controllers/_exports';

const SubmitRoleplayContent = () => {
  const roleplayView = useRoleplayStore((state) => state?.roleplayView);
  const [scenarios, setScenarios] = useState([]);
  const { mutate: submitRoleplay, isLoading } = useSubmitRoleplay();
  const { mutate: processingIA } = useSubmitRoleplayForProcessingWithAI();
  let location = useLocation();
  let history = useHistory();
  const org_id = Organization.getSelectedOrg()?.slug;
  useEffect(() => {
    setScenarios(roleplayView?.scenarios || []);
  }, [roleplayView]);

  const dataForAIProcessing = useMemo(() => {
    if (Object.keys(roleplayView).length === 0) return {};

    const rating_criteria = roleplayView?.rating_criteria?.map((x) => ({
      criteria_id: x?.uuid,
      name: x?.name,
      description: x?.description === null ? '' : x?.description,
    }));
    const scenarios = [];
    const description = roleplayView?.role_play?.description;
    roleplayView?.scenarios?.forEach((sce) => {
      const item = {
        scenario_id: sce?.uuid,
        name: sce?.name,
        description:
          sce?.description === null || sce?.description === '' ? description : sce?.description,
        response_id: sce?.response?.uuid,
        video_key: '',
        rating_criteria,
      };
      scenarios.push(item);
    });

    return {
      type: roleplayView?.role_play?.name,
      description: roleplayView?.role_play?.description,
      scenarios,
    };
  }, [roleplayView]);

  const handleVideKey = (data) => {
    if (
      FeatureFlag.enabled('AUTORATING') &&
      !Boolean(Organization.getSelectedOrg()?.ai_integration)
    )
      return;

    const temp = data?.roleplay?.scenarios || [];
    const scenarios = dataForAIProcessing?.scenarios || [];
    scenarios.forEach((x) => {
      const index = temp.findIndex((item) => item?.scenario_id === x?.scenario_id);
      if (index !== -1 || index !== null) {
        x.video_key = temp[index]?.video_key;
      }
    });
    processingIA({ org_id, roleplay_id: roleplayView.uuid, requestBody: dataForAIProcessing });
  };

  const handleSubmit = () => {
    submitRoleplay(
      { roleplay_id: roleplayView.uuid },
      {
        onSuccess: (response) => {
          handleVideKey(response?.data);
          history.push({
            pathname: `/roleplay`,
            state: {
              prevPath: `${location.pathname}${location.search ? location.search : ''}`,
            },
          });
        },
      }
    );
  };

  const enabled = useMemo(
    () => scenarios.every((item) => item.response && item.response.video),
    [scenarios]
  );

  return (
    <div>
      {scenarios.map((scenario, index) => {
        return (
          <SubmitRoleplayCard
            title={scenario.name !== 'Default' ? scenario.name : roleplayView?.name}
            description={
              scenario.description ? scenario.description : roleplayView?.role_play.description
            }
            index={index + 1}
            completed={scenario.response.video ? true : false}
            key={index}
          />
        );
      })}
      <div className={styles.submitButton}>
        <CustomButton onClick={handleSubmit} disabled={!enabled || isLoading}>
          Submit
        </CustomButton>
      </div>
    </div>
  );
};

export default SubmitRoleplayContent;
